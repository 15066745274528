import { Component, createRef } from "react";
import { Button } from "primereact/button";
import Header from "../components/Header";
import { Menu } from 'primereact/menu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Navigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from 'primereact/tabview';
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from 'primereact/inputnumber';


import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService"
import DocumentoItem from "lirida-back-service/Servicos/Documento/DocumentoItem";
import DepartamentoService from "lirida-back-service/Servicos/Departamento/DepartamentoService"
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa'
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import DocumentoRelacionamento from "lirida-back-service/Servicos/DocumentoRelacionamento/DocumentoRelacionamento";
import GoogleAPI from "lirida-back-service/Servicos/Google/GoogleAPI";
import Financeiro from 'lirida-back-service/Servicos/Financeiro/Financeiro'
import TabelaPrecoService from "lirida-back-service/Servicos/TabelaPreco/TabelaPrecoService";
import DocumentoArquivo from 'lirida-back-service/Servicos/DocumentoArquivo/DocumentoArquivo';
import DocumentoAtendimento from 'lirida-back-service/Servicos/DocumentoAtendimento/DocumentoAtendimento';



import Constante from "../util/Constante";
import Util from "../util/util";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";



export default class DocumentoPg extends Component {

    mensagens = createRef();
    constante = new Constante();
    util = new Util(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    departamentoService = new DepartamentoService(this.constante.token, this.constante.url_base);
    googleAPI = new GoogleAPI(this.constante.token, this.constante.url_base);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base)
    tabelaPrecoService = new TabelaPrecoService(this.constante.token, this.constante.url_base)


    state = {
        liberarAcesso: {
            detalhe: false,
        },
        salvarCarregando: true,

        listaDepartamento: [],
        listaVwContadorDocumento: [],
        listaDocumento: [],
        documentoItem: new DocumentoItem(),
        conteudo: '',


        // state tela detalhe Documento
        listaRelacionamento: [],
        documentoDetalhe: new Documento(),
        enderecoColetaDetalhe: { logradouro: '', cidade: '', bairro: '', numero: '', latitude: '', longitude: '' },
        enderecoEntregaDetalhe: { logradouro: '', cidade: '', bairro: '', numero: '', latitude: '', longitude: '' },

        // state formulario
        telaFormulario: false,
        pessoaUsuario: new Pessoa(),
        pessoaSolicitante: new Pessoa(),
        pessoaRemetente: new Pessoa(),
        pessoaDestinatario: new Pessoa(),
        enderecoColeta: new Endereco(),
        enderecoEntrega: new Endereco(),
        financeiro: new Financeiro(),
        seqItem: '',
        valorServico: 0,
        valorItem: 0,
        valorTotal: 0,
        listaTabelaPreco: [],
        responsavelColeta: new Pessoa(),
        responsavelEntrega: new Pessoa(),
        listaEnderecoGoogleColeta: [],
        listaEnderecoGoogleEntrega: [],
        documento: new Documento(),
        documentoItemFormulario: new DocumentoItem(),
        documentoRelacionamentoSolicitante: new DocumentoRelacionamento(),
        documentoRelacionamentoRemetente: new DocumentoRelacionamento(),
        documentoRelacionamentoDestinatario: new DocumentoRelacionamento(),


        //STATE ARQUIVO
        documentoArquivo: new DocumentoArquivo(),
        nomeArquivo: '',
        tipoArquivo: '',
        base64Arquivo: '',
        url_baseArquivo: '',
        listaArquivo: [],
        vModalDeletarArquivo: false,

        //STATE ATENDIMENTO
        documentoAtendimento: new DocumentoAtendimento(),
        listaDocumentoAtendimento: [],
        vModalDeletarAtendimento: false,

        listaFrota: [],
        frota: null,
        telaDetalhe: false
    }


    componentDidMount() {
        this.iniciar();
        this.listarTabelaPreco()
        this.listarFrota()
        this.listar()
    }


    async iniciar() {
        this.pessoaUsuario = this.state.pessoaUsuario
        this.url_baseArquivo = await this.util.buscarUrlBaseArquivo();
        this.retornoLocalStorage = await this.util.buscarLocalStorage('PESSOA_USUARIO')
        console.log("USUARIO LOCALSTORAGE", this.retornoLocalStorage)
        this.pessoaUsuario = this.retornoLocalStorage


        if (!this.pessoaUsuario) {
            this.setState({ liberarAcessoTelaLogin: true })
        }



        this.retorno = await this.departamentoService.listar(
            this.constante.seqUsuario
        );
        console.log("RETORNO DEPARTAMENTO", this.retorno.objeto[1]);


        // //listando ContadorDocumento
        // this.retornoVwContadorDocumento = await this.documentoService.listarContadorDocumento(
        //         this.constante.seqUsuario,
        //         this.constante.seqTipoDocumentoPedidoVenda
        //     );
        // console.log("RETORNO CONTADOR ", this.retornoVwContadorDocumento.objeto);

        // Passando pro state
        this.setState({
            listaDepartamento: this.retorno.objeto,
            pessoaUsuario: this.pessoaUsuario
        });
        // this.listar(this.retornoVwContadorDocumento.objeto);
    }

    async listar(pTipoDocumentoProcesso) {
        // let tipoDocumentoProcesso = pTipoDocumentoProcesso;
        console.log("PROCESSO QUE ENTROU", pTipoDocumentoProcesso);

        // this.retornoVwContadorDocumento =
        //     await this.documentoService.listarContadorDocumento(
        //         this.constante.seqUsuario,
        //         this.constante.seqTipoDocumentoPedidoVenda
        //     );
        // console.log("SEGUNDA LISTA", this.retornoVwContadorDocumento.objeto);

        this.setState({
            // listaVwContadorDocumento: this.retornoVwContadorDocumento.objeto,
            listaDocumento: [],
            tipoDocumentoProcesso: pTipoDocumentoProcesso
        });


        let pesquisa = [
            this.util.criarObjetoPesquisa(
                "documento.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "documento.seq_tipo_documento_processo",
                this.constante.seqTipoDocumentoProcessoSemAgendamento,
                // tipoDocumentoProcesso._seqTipoDocumentoProcesso,
                "AND",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "documento.pessoa_nome",
                this.state.conteudo,
                "AND",
                "TEXTO"
            ),


        ];


        this.retornoListaDocumento = await this.documentoService.listarComFiltro(pesquisa);
        this.listaDocumento = this.retornoListaDocumento.objeto

        for (let i = 0; i < this.listaDocumento.length; i++) {
            this.listaDocumento[i].dataCadastro = this.util.formatarData(this.listaDocumento[i].dataCadastro);
            this.listaDocumento[i].dataProgramada = this.util.formatarData(this.listaDocumento[i].dataProgramada);
            this.listaDocumento[i].precoUnitario = this.util.formatarValor(this.listaDocumento[i].precoUnitario);
            this.listaDocumento[i].valorTotal = this.util.formatarValor(this.listaDocumento[i].valorTotal);
            this.listaDocumento[i].valorLiquido = this.util.formatarValor(this.listaDocumento[i].valorLiquido);
        }

        console.log("LISTA DOCUMENTO", this.retornoListaDocumento.objeto);
        this.setState({
            listaDocumento: this.retornoListaDocumento.objeto,
        });
    }


    async listarFrota() {
        this.pessoaUsuario = this.state.pessoaUsuario

        this.listaPesquisa = [
            this.util.criarObjetoPesquisa(
                "pessoa_relacionamento.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa_relacionamento.seq_pessoa_relacionada",
                this.pessoaUsuario._seqPessoa,
                "AND",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa_relacionamento.seq_tipo_relacionamento",
                this.constante.seqTipoRelacionamentoConviteAceito,
                "AND",
                "TEXTO"
            ),

        ];

        this.retorno = await this.pessoaService.listarComFiltro(this.listaPesquisa)
        console.log(this.retorno.objeto)
        this.listaFrota = this.retorno.objeto
        console.log('lista frotaaa', this.listaFrota);
        this.setState({ listaFrota: this.listaFrota })
    }


    async selecionar(pDocumento) {
        console.log("===>")
        console.log(pDocumento);
        this.setState({ telaFormulario: true, salvarCarregando: false })

        // LISTANDO RELACIONAMENTO ITEM
        this.retornoDocumento = await this.documentoService.listarRelacionamento(pDocumento)
        this.listaRelacionamento = this.retornoDocumento.objeto
        console.log("relacionamentoItem", this.listaRelacionamento)


        for (let i = 0; i < this.listaRelacionamento.length; i++) {
            console.log("no for pessoa solicitante");
            if (this.listaRelacionamento[i].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoSolicitante) {

                //BUSCANDO PESSOA RELACIONAMENTO SOLICITANTE
                this.retornoPessoaSolicitante = await this.pessoaService.buscarPorSeqPessoa(this.listaRelacionamento[i].seqPessoaRelacionada, this.constante.seqUsuario)
                console.log("pessoa coletaaa", this.retornoPessoaSolicitante.objeto);

                this.setState({ pessoaSolicitante: this.retornoPessoaSolicitante.objeto })
            }
        }

        for (let i = 0; i < this.listaRelacionamento.length; i++) {
            console.log("no for pessoa coleta");
            if (this.listaRelacionamento[i].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoColeta) {

                //BUSCANDO PESSOA RELACIONAMENTO COLETA
                this.retornoPessoaColeta = await this.pessoaService.buscarPorSeqPessoa(this.listaRelacionamento[i].seqPessoaRelacionada, this.constante.seqUsuario)
                console.log("pessoa coletaaa", this.retornoPessoaColeta.objeto);

                this.setState({ pessoaRemetente: this.retornoPessoaColeta.objeto })
            }
        }

        for (let i = 0; i < this.listaRelacionamento.length; i++) {
            console.log("no for pessoa entrega");
            if (this.listaRelacionamento[i].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoEntrega) {

                //BUSCANDO PESSOA RELACIONAMENTO COLETA
                this.retornoPessoaEntrega = await this.pessoaService.buscarPorSeqPessoa(this.listaRelacionamento[i].seqPessoaRelacionada, this.constante.seqUsuario)
                console.log("pessoa entregaaaa", this.retornoPessoaEntrega.objeto);

                this.setState({ pessoaDestinatario: this.retornoPessoaEntrega.objeto })
            }
        }

        //listando Arquivo
        this.retornoListaArquivo = await this.documentoService.listarArquivo(pDocumento);
        this.listaArquivo = this.retornoListaArquivo.objeto;

        console.log("lista arquivoooooooooo", this.listaArquivo);


        //listando atendimento
        this.retornoAtendimento = await this.documentoService.listarAtendimento(pDocumento);
        this.listaDocumentoAtendimento = this.retornoAtendimento.objeto
        console.log("lista atendimento", this.listaDocumentoAtendimento);



        this.setState({
            documento: pDocumento,
            listaRelacionamento: this.listaRelacionamento,
            listaArquivo: this.listaArquivo,
            listaDocumentoAtendimento: this.listaDocumentoAtendimento

        })


        // BUSCANDO POR EMAIL
        this.retornoPessoaEndereco = await this.pessoaService.buscarPorEmail(pDocumento._pessoaEmail, this.constante.seqUsuario)
        this.pessoaEndereco = this.retornoPessoaEndereco.objeto
        console.log("pessoa endereco", this.retornoPessoaEndereco.objeto)


        // LISTANDO ENDERECO 
        this.retornoEndereco = await this.pessoaService.listarEndereco(this.pessoaEndereco)
        this.endereco = this.retornoEndereco.objeto
        console.log("ENDERECO DA PESSOA", this.retornoEndereco.objeto)


        //ENDERECO COLETA DETALHE
        this.enderecoColeta = this.state.enderecoColeta
        this.enderecoColeta.logradouro = this.endereco[0].logradouro
        this.enderecoColeta.bairro = this.endereco[0].bairro
        this.enderecoColeta.numero = this.endereco[0].numero
        this.enderecoColeta.cidade = this.endereco[0].cidade
        this.enderecoColeta.latitude = this.endereco[0].latitude
        this.enderecoColeta.longitude = this.endereco[0].longitude


        //endereco entrega detalhe
        this.enderecoEntrega = this.state.enderecoEntrega
        this.enderecoEntrega.logradouro = this.endereco[1].logradouro
        this.enderecoEntrega.bairro = this.endereco[1].bairro
        this.enderecoEntrega.numero = this.endereco[1].numero
        this.enderecoEntrega.cidade = this.endereco[1].cidade
        this.enderecoEntrega.latitude = this.endereco[1].latitude
        this.enderecoEntrega.longitude = this.endereco[1].longitude

        this.setState({
            enderecoColeta: this.enderecoColeta,
            enderecoEntrega: this.enderecoEntrega
        })

    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }


    // SALVANDO DOCUMENTO
    async salvar() {
        this.setState({ salvarCarregando: true });

        //pessoaSolicitante
        this.pessoaSolicitante = this.state.pessoaSolicitante;
        this.pessoaSolicitante.seqUsuario = this.constante.seqUsuario;
        this.pessoaSolicitante.seqTabelaPreco = this.constante.seqTabelaPreco;
        this.pessoaSolicitante.seqPessoaPerfil = this.constante.seqPerfilSolicitante;
        this.retornoPessoaSolicitante = await this.pessoaService.salvar(this.pessoaSolicitante);
        this.pessoaSolicitante = this.retornoPessoaSolicitante.objeto;
        console.log("pessoa solicitante", this.retornoPessoaSolicitante.objeto);


        // pessoaRementente
        this.pessoaRemetente = this.state.pessoaRemetente;
        this.pessoaRemetente.seqUsuario = this.constante.seqUsuario;
        this.pessoaRemetente.seqPessoaPerfil = this.constante.seqPerfilRemetente;
        this.retornoPessoaRemetente = await this.pessoaService.salvar(this.pessoaRemetente);
        this.pessoaRemetente = this.retornoPessoaRemetente.objeto;
        console.log("pessoa remetente", this.retornoPessoaRemetente.objeto)


        // pessoaDestinatario
        this.pessoaDestinatario = this.state.pessoaDestinatario;
        this.pessoaDestinatario.seqUsuario = this.constante.seqUsuario;
        this.pessoaDestinatario.seqPessoaPerfil = this.constante.seqPerfilDestinatario;
        this.retornoPessoaDestinatario = await this.pessoaService.salvar(this.pessoaDestinatario);
        this.pessoaDestinatario = this.retornoPessoaDestinatario.objeto;
        console.log("pessoa destinatario", this.retornoPessoaDestinatario.objeto)


        //enderecoRemetente
        this.enderecoColeta = this.state.enderecoColeta;
        this.enderecoColeta.seqUsuario = this.constante.seqUsuario;
        this.enderecoColeta.seqPessoa = this.pessoaSolicitante._seqPessoa;
        this.enderecoColeta.seqTipoEndereco = this.constante.seqEnderecoCobranca;
        this.retornoEnderecoColeta = await this.pessoaService.salvarEndereco(this.enderecoColeta);
        this.enderecoColeta = this.retornoEnderecoColeta.objeto;
        console.log("endereco remetente", this.retornoEnderecoColeta.objeto);

        //enderecoDestinatario
        this.enderecoEntrega = this.state.enderecoEntrega;
        this.enderecoEntrega.seqUsuario = this.constante.seqUsuario;
        this.enderecoEntrega.seqPessoa = this.pessoaSolicitante._seqPessoa;
        this.enderecoEntrega.seqTipoEndereco = this.constante.seqEnderecoEntrega;
        this.retornoEnderecoEntrega = await this.pessoaService.salvarEndereco(this.enderecoEntrega);
        this.enderecoEntrega = this.retornoEnderecoEntrega.objeto;
        console.log("endereco destinatario", this.retornoEnderecoEntrega.objeto);


        // documento
        this.documento = this.state.documento;
        this.documento.seqUsuario = this.constante.seqUsuario;
        this.documento.seqPessoa = this.pessoaSolicitante._seqPessoa;
        this.documento.seqPessoaRecebedor = this.pessoaUsuario._seqPessoa
        this.documento.vendedorNome = this.pessoaSolicitante.nome
        this.documento.seqTipoDocumento = this.constante.seqTipoDocumentoPedidoVenda;
        this.documento.quantidade = 1

        // SE ESCOLHER O TRANPORTADOR ELE ENTRA NO IF //
        if (this.state?.frota?._seqPessoaRelacionada) {
            console.log("ENTREI NO IF  DO DOCUMENTO");

            this.documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoaAgendado
            this.documento.status = 1
            this.retornoDocumento = await this.documentoService.salvar(this.documento);
            this.documento = this.retornoDocumento.objeto;
            console.log("salvei documento do if", this.documento);

            //savando relacionamento do entregador//
            this.documentoRelacionamentoEntregador = new DocumentoRelacionamento();
            this.documentoRelacionamentoEntregador.seqUsuario = this.constante.seqUsuario
            this.documentoRelacionamentoEntregador.seqDocumento = this.documento._seqDocumento
            this.documentoRelacionamentoEntregador.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoEntregaAceita
            this.documentoRelacionamentoEntregador.seqPessoaRelacionada = this.state.frota._seqPessoaRelacionada;
            this.documentoRelacionamentoEntregador.situacao = "ativo";

            this.retornoDocumentoRelacionamentoEntregador = await this.documentoService.salvarRelacionamento(this.documentoRelacionamentoEntregador)
            this.documentoRelacionamentoEntregador = this.retornoDocumentoRelacionamentoEntregador.objeto
            console.log(" salvei releacionamento entregador NO IF", this.documentoRelacionamentoEntregador)
        }

        else {
            this.documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoSemAgendamento
            this.documento.status = 0
            this.retornoDocumento = await this.documentoService.salvar(this.documento);
            this.documento = this.retornoDocumento.objeto;
            console.log(" salvei sem documento transportador", this.documento);
        }

        //documentoItem
        this.documentoItemFormulario = this.state.documentoItemFormulario
        this.documentoItemFormulario.seqUsuario = this.constante.seqUsuario;
        this.documentoItemFormulario.seqItem = this.constante.seqItenVenda;
        this.documentoItemFormulario.seqPessoaRecebedor = this.pessoaUsuario._seqPessoa
        this.documentoItemFormulario.seqPessoa = this.pessoaSolicitante._seqPessoa;
        this.documentoItemFormulario.seqDocumento = this.documento._seqDocumento;
        this.documentoItemFormulario.valorTotal = this.documento.valorTotal
        this.documentoItemFormulario.quantidade = 1;


        // SE ESCOLHER O TRANPORTADOR ELE ENTRA NO IF //
        if (this.state?.frota?._seqPessoaRelacionada) {

            console.log("ENTREI NO IF  DO DOCUMENTO ITEM");
            this.documentoItemFormulario.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoaAgendado;
            this.documentoItemFormulario.status = 1;
            this.retorno = await this.documentoService.salvarItem(this.documentoItemFormulario);
            this.documentoItemFormulario = this.retorno.objeto;
            console.log("documento item DO IF", this.documentoItemFormulario);
        }


        else {

            this.documentoItemFormulario.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoSemAgendamento;
            this.documentoItemFormulario.status = 0;
            this.retorno = await this.documentoService.salvarItem(this.documentoItemFormulario);
            this.documentoItemFormulario = this.retorno.objeto;
            console.log("documento item", this.documentoItemFormulario);

        }

        // documentoRelacionamentoSolicitante
        this.documentoRelacionamentoSolicitante = this.state.documentoRelacionamentoSolicitante;
        this.documentoRelacionamentoSolicitante.seqUsuario = this.constante.seqUsuario;
        this.documentoRelacionamentoSolicitante.seqDocumento = this.documento._seqDocumento;
        this.documentoRelacionamentoSolicitante.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoSolicitante;
        this.documentoRelacionamentoSolicitante.seqPessoaRelacionada = this.pessoaSolicitante._seqPessoa;
        this.documentoRelacionamentoSolicitante.situacao = "Ativo";
        console.log(this.documentoRelacionamentoSolicitante);
        this.retorno = await this.documentoService.salvarRelacionamento(
            this.documentoRelacionamentoSolicitante
        );

        // documentoRelacionamentoRemetente
        this.documentoRelacionamentoRemetente = this.state.documentoRelacionamentoRemetente;
        this.documentoRelacionamentoRemetente.seqUsuario = this.constante.seqUsuario;
        this.documentoRelacionamentoRemetente.seqDocumento = this.documento._seqDocumento;
        this.documentoRelacionamentoRemetente.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoColeta;
        this.documentoRelacionamentoRemetente.seqPessoaRelacionada = this.pessoaRemetente._seqPessoa;
        this.documentoRelacionamentoRemetente.situacao = "Ativo";
        console.log(this.documentoRelacionamentoRemetente);
        this.retorno = await this.documentoService.salvarRelacionamento(this.documentoRelacionamentoRemetente
        );

        // documentoRelacionamentoDestinatario
        this.documentoRelacionamentoDestinatario = this.state.documentoRelacionamentoDestinatario
        this.documentoRelacionamentoDestinatario.seqUsuario = this.constante.seqUsuario;
        this.documentoRelacionamentoDestinatario.seqDocumento = this.documento._seqDocumento;
        this.documentoRelacionamentoDestinatario.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoEntrega;
        this.documentoRelacionamentoDestinatario.seqPessoaRelacionada = this.pessoaDestinatario._seqPessoa;
        this.documentoRelacionamentoDestinatario.situacao = "Ativo";
        console.log(this.documentoRelacionamentoDestinatario);
        this.retorno = await this.documentoService.salvarRelacionamento(
            this.documentoRelacionamentoDestinatario
        );

        //GERANDO FINANCEIRO
        // console.log('entrando financeiro')

        // this.financeiro = this.state.financeiro
        // this.financeiro.seqTipoDocumento = this.constante.seqTipoDocumentoArquivoMorto;
        // this.financeiro.seqUsuario = this.documento.seqUsuario;
        // this.financeiro.seqPessoa = this.documento.seqPessoa;
        // this.financeiro.seqContaBancaria = this.constante.seqContaBancaria;
        // this.financeiro.seqContaFinanceira = this.constante.seqContaFinanceiraReceber;
        // this.financeiro.valorProgramado = this.documento.valorTotal;
        // this.financeiro.dataProgramada = new Date();
        // this.financeiro.documento = this.state.pessoaUsuario.documento;
        // this.financeiro.tipoDocumento = this.state.pessoaUsuario.tipoDocumento;
        // this.financeiro.nome = this.documento.vendedorNome;
        // this.financeiro.qtdeBaixa = 0;
        // this.financeiro.seqFormaPagamento = this.constante.seqFormaDePagamentoPix;
        // this.financeiro.seqDocumento = this.documento._seqDocumento;
        // this.financeiro.seqDocumentoItem = this.documentoItemFormulario._seqDocumentoItem;

        // this.retornoFinanceiro = await this.financeiroService.salvar(this.financeiro);
        // console.log(this.retornoFinanceiro.objeto);
        // console.log('financeiro +++++++=++++=====+');
        //

        // alert("seu documento foi gerado")

        this.setState({
            salvarCarregando: false,
            salvarCheck: true,
            documento: this.documento,

        })

        setTimeout(() => {
            this.setState({ salvarCheck: false });
            this.mensagens.current.show([
                { severity: 'success', summary: 'Documento salvo com sucesso!!', sticky: true },
            ]);
        }, 2000);

    }

    //deletar Documento
    async deletarDocumento() {
        console.log('DOCUMENTO DELETADO')
        this.setState({ deletarCarregando: true });
        this.documento = this.state.documento;
        this.documento.seqTipoDocumentoProcesso = this.constante.seqProcessoDocumentoDeletado;
        this.retorno = await this.documentoService.salvar(this.documento);
        setTimeout(() => this.setState({ deletarCarregando: false, }), 2000);
        this.voltar();
    }


    //Listando EnderecoColeta GoogleApi
    async listarEnderecoGoogleColeta(pTexto) {
        this.retorno = await this.googleAPI.listarEndereco(pTexto);
        console.log("listandoenderecoGoogle", this.retorno.predictions);
        console.log("lista endereco gooogle coleta", this.retorno.predictions);

        this.setState({
            listaEnderecoGoogleColeta: this.retorno.predictions,
        });
        if (this.retorno.predictions.length === 1) {
            this.buscarEnderecoGoogleColeta(this.retorno.predictions[0].place_id);
        }
    }


    //Buscando EnderecoColeta GoogleApi
    async buscarEnderecoGoogleColeta(pPlaceID) {
        console.log("+++++++++++++++++++++++++")
        console.log(pPlaceID);
        this.retorno = await this.googleAPI.buscarEndereco(pPlaceID);
        console.log(this.retorno);

        this.setState({
            enderecoColeta: {
                ...this.state.enderecoColeta,
                latitude: this.retorno.result.geometry.location.lat,
                longitude: this.retorno.result.geometry.location.lng,
                logradouro: this.retorno.result.address_components[1].long_name,
                bairro: this.retorno.result.address_components[2].long_name,
                numero: this.retorno.result.address_components[0].long_name,
                cidade: this.retorno.result.address_components[3].long_name,
                uf: this.retorno.result.address_components[4].short_name,
            },
        });
    }



    //listando EnderecoEntrega GoogleApi
    async listarEnderecoGoogleEntrega(pTexto) {
        this.retorno = await this.googleAPI.listarEndereco(pTexto);
        console.log(this.retorno.predictions);
        this.setState({
            listaEnderecoGoogleEntrega: this.retorno.predictions,
        });
        if (this.retorno.predictions.length === 1) {
            this.buscarEnderecoGoogleEntrega(this.retorno.predictions[0].place_id);
        }
    }



    //Buscando EnderecoEntrega GoogleApi
    async buscarEnderecoGoogleEntrega(pPlaceID) {
        console.log("+++++++++++++++++++++++++")
        console.log(pPlaceID);
        this.retorno = await this.googleAPI.buscarEndereco(pPlaceID);
        console.log(this.retorno);

        this.setState({
            enderecoEntrega: {
                ...this.state.enderecoEntrega,
                latitude: this.retorno.result.geometry.location.lat,
                longitude: this.retorno.result.geometry.location.lng,
                logradouro: this.retorno.result.address_components[1].long_name,
                bairro: this.retorno.result.address_components[2].long_name,
                numero: this.retorno.result.address_components[0].long_name,
                cidade: this.retorno.result.address_components[3].long_name,
                uf: this.retorno.result.address_components[4].short_name,
            },
        });

    }



    //calculando distancia
    async calculoDistacia(item) {

        this.enderecoColeta = this.state.enderecoColeta
        this.enderecoEntrega = this.state.enderecoEntrega
        this.listaTabelaPreco = this.state.listaTabelaPreco
        // console.log("item que entrou", item)


        // this.valueItem é usado apenas no input select
        this.valueItem = item
        console.log("valueItem", this.valueItem)
        this.setState({ value: this.valueItem })
        //


        for (let i = 0; i < this.listaTabelaPreco.length; i++) {
            if (this.listaTabelaPreco[i].seqItem === item.seqItem) {
                console.log("no for");

                this.valorItem = this.listaTabelaPreco[i].precoMaximo

                this.setState({
                    valorItem: this.valorItem,
                    seqItem: item.seqItem,
                })

                console.log(this.state.valorItem)

            }
            console.log("saindo");
        }


        this.retorno = await this.googleAPI.buscarDistanciaPorLatLon(

            this.enderecoColeta.latitude,
            this.enderecoColeta.longitude,
            this.enderecoEntrega.latitude,
            this.enderecoEntrega.longitude,
        )

        this.distancia = this.retorno.rows[0].elements[0].distance.value
        console.log("distanciaaaaaaaa")
        console.log(this.retorno.rows[0].elements[0].distance.value)

        this.km = this.distancia / 1000
        console.log('kmmmmmmmmmm')
        console.log(this.km)

        this.valorServico = this.km * this.state.valorItem

        this.setState({ valorServico: this.valorServico })

    }


    async listarTabelaPreco() {
        this.retornoTabelaPreco = await this.tabelaPrecoService.listarTabelaPreco(
            this.constante.seqTabelaPreco,
            this.constante.seqItemCategoria,
            ""
        );
        console.log("retorno Tabela precooooo", this.retornoTabelaPreco.objeto);

        this.setState({ listaTabelaPreco: this.retornoTabelaPreco.objeto });
    }


    // ARQUIVO //

    async selecionarArquivo(pArquivo) {
        console.log(pArquivo.name);
        this.tipoArquivo = String(pArquivo.name).split('.').slice(-1)[0];
        console.log(this.tipoArquivo);
        const retorno = await this.util.pegarBase64(pArquivo);
        console.log(retorno)
        this.setState({ base64Arquivo: retorno, tipoArquivo: this.tipoArquivo });
    }


    async salvarArquivo() {
        this.setState({ salvarCarregando: true })
        this.documento = this.state.documento;
        this.base64Arquivo = this.state.base64Arquivo;
        this.nomeArquivo = this.state.nomeArquivo;
        this.tipoArquivo = this.state.tipoArquivo;
        this.nomeArquivo = this.nomeArquivo + '.' + this.tipoArquivo
        this.documentoArquivo = new DocumentoArquivo();
        this.documentoArquivo.seqDocumento = this.documento._seqDocumento;
        this.documentoArquivo.seqUsuario = this.constante.seqUsuario;
        this.documentoArquivo.situacao = 'ATIVO';
        this.documentoArquivo.arquivo = this.base64Arquivo;
        this.documentoArquivo.arquivoOriginal = this.nomeArquivo;
        this.documentoArquivo.seqTipoArquivo = this.constante.seqTipoArquivoInformacoesAdicionais;
        this.retornoArquivo = await this.documentoService.salvarArquivo(this.documentoArquivo);
        console.log("Arquivo")
        console.log(this.retornoArquivo);

        this.listarArquivo(this.documento);

        this.setState({
            salvarCarregando: false,
            base64Arquivo: '',
            nomeArquivo: '',
            tipoArquivo: '',
        })

    }

    async listarArquivo(pDocumento) {
        if (pDocumento) {
            this.documento = pDocumento
        } else {
            this.documento = this.state.documento
        }
        this.listaArquivo = await this.documentoService.listarArquivo(this.documento);
        this.listaArquivo = this.listaArquivo.objeto;
        console.log(this.listaArquivo);
        this.setState({ listaArquivo: this.listaArquivo });
    }


    async deletarArquivo() {
        this.documentoArquivo = this.state.documentoArquivo;
        await this.documentoService.deletarArquivo(this.documentoArquivo);
        this.listarArquivo();
    }

    // ATENDIMENTO //

    async salvarAtendimento() {
        this.setState({ salvarCarregando: true })
        this.documento = this.state.documento;
        this.documentoAtendimento = this.state.documentoAtendimento;
        this.documentoAtendimento.seqUsuario = this.constante.seqUsuario;
        this.documentoAtendimento.seqTipoAtendimento = this.constante.seqTipoAtendimentoInformacoesAdicionais;
        this.documentoAtendimento.seqDocumento = this.documento._seqDocumento;
        this.documentoAtendimento.situacao = 'ATIVO';
        this.retorno = await this.documentoService.salvarAtendimento(this.documentoAtendimento);
        console.log(this.retorno);
        this.setState({
            salvarCarregando: false,
            documentoAtendimento: new DocumentoAtendimento()
        })
        this.listarDocumentoAtendimento(this.documento);
    }


    async listarDocumentoAtendimento(pDocumento) {
        if (pDocumento) {
            this.documento = pDocumento;
        } else {
            this.documento = this.state.documento;
        }

        this.retorno = await this.documentoService.listarAtendimento(this.documento);
        this.listaDocumentoAtendimento = this.retorno.objeto
        console.log(this.listaDocumentoAtendimento);
        this.setState({
            listaDocumentoAtendimento: this.listaDocumentoAtendimento
        })
    }

    selecionarDocumentoAtendimento(e) {
        console.log(e)
        this.setState({ documentoAtendimento: e });
    }

    async deletarDocumentoAtendimento() {
        this.documentoAtendimento = this.state.documentoAtendimento;
        await this.documentoService.deletarAtendimento(this.documentoAtendimento);
        console.log("ATENDIMENTO DELETADO")
        this.setState({ documentoAtendimento: new DocumentoAtendimento() });
        this.listarDocumentoAtendimento();
    }

    voltar() {
        this.setState({
            telaFormulario: false,
            documento: new Documento(),
            listaRelacionamento: [],
            pessoaSolicitante: new Pessoa(),
            pessoaRemetente: new Pessoa(),
            pessoaDestinatario: new Pessoa()
        })

        this.listar()
    }

    render() {
        return <div>
            {/* NAVEGAÇÃO */}
            {this.state.liberarAcesso.detalhe && <Navigate to="/detalhe" />}


            <Dialog header="Alerta" visible={this.state.vModalDeletar} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletar: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletarDocumento();
                            this.setState({ vModalDeletar: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletar: false, })} />
                    </div>
                </div>
            </Dialog>


            <Dialog header="Alerta" visible={this.state.vModalDeletarArquivo} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletarArquivo: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletarArquivo();
                            this.setState({ vModalDeletarArquivo: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletarArquivo: false, })} />
                    </div>
                </div>
            </Dialog>


            <Dialog header="Alerta" visible={this.state.vModalDeletarAtendimento} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletarAtendimento: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletarDocumentoAtendimento();
                            this.setState({ vModalDeletarAtendimento: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletarAtendimento: false, })} />
                    </div>
                </div>
            </Dialog>


            {/* TELA */}
            <Header />
            {this.state.telaFormulario === false ? <>
                <main style={{ width: '95%', margin: '20px auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                            <div>
                                <h1 style={{ fontSize: 22 }}>Documento</h1>
                                <h2 style={{ fontSize: 20, color: 'gray' }}>documento</h2>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                            <InputText placeholder="Pesquisar" value={this.state.conteudo} onChange={e => this.setState({ conteudo: e.target.value })} />
                            <Button label="Buscar" className="p-button-raised" onClick={() => this.listar()} />
                            <Button label="Novo" className="p-button-raised" onClick={() => this.setState({ telaFormulario: true })} />

                        </div>
                    </div>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 8fr', marginTop: 20, gap: 20, }}>
                        <Menu model={[{ label: 'Departamento', items: [{ label: 'Processo 1' }, { label: 'Processo 1' }, { label: 'Processo 1' }] }]} />

                        <DataTable selectionMode="single" showGridlines removableSort value={this.state.listaDocumento} onSelectionChange={e => this.selecionar(e.value)}>
                            <Column field="_tipoDocumentoProcessoNome" header="Status" sortable></Column>
                            <Column field="_pessoaNome" header="Nome" sortable></Column>
                            {/* <Column field="quantidade" header="Quantidade" sortable></Column> */}
                            <Column field="precoUnitario" header="Preco unitario" sortable></Column>
                            <Column field="valorTotal" header="Valor total" sortable></Column>
                            <Column field="valorLiquido" header="valor liquido" sortable></Column>
                            <Column field="dataCadastro" header="Data de cadastro" sortable></Column>
                        </DataTable>
                    </div>
                </main >


            </> : // FORMULARIO //

                <form onSubmit={e => { e.preventDefault(); this.salvar(e.target) }}>
                    <main style={{ width: '95%', margin: '20px auto' }}>
                        <Messages ref={this.mensagens} />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                                <Button label={<i className="pi pi-arrow-left"></i>} className="p-button-raised" type="button" onClick={() => this.voltar()} />
                                <div>
                                    <h1 style={{ fontSize: 22 }}>Formulario</h1>
                                    <h2 style={{ fontSize: 20, color: 'gray' }}>Faça seu pedido!</h2>

                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, marginLeft: "auto" }}>
                                {this.state.salvarCheck ?
                                    <Button label="Salvo" icon={<i className="pi pi-check"></i>} className="p-button-success" />
                                    :
                                    <Button label="Salvar" type="submit" className="p-button-raised" />
                                }
                                {this.state.documento._seqDocumento && <Button loading={this.state.deletarCarregando}
                                    label="Deletar"
                                    className="p-button-danger" onClick={() => this.setState({ vModalDeletar: true, })} />}
                            </div>

                        </div>

                        {/* Dados do solicitante */}

                        <div> <br />
                            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                                <Button icon="pi pi-user" className="p-button-rounded p-button-info p-button-outlined" aria-label="User" />
                                <h3>Dados do solicitante</h3>
                            </div>

                            <div style={{ display: "grid", gridTemplateColumns: " 1fr 1fr 1fr", gap: 20, border: '1px solid lightgray', padding: 20, borderRadius: 3, marginTop: 10 }}>
                                <div>
                                    <label>Nome</label><br />
                                    <InputText style={{ width: '100%' }}
                                        required
                                        onChange={(pTexto) =>
                                            this.setState({
                                                pessoaSolicitante: {
                                                    ...this.state.pessoaSolicitante,
                                                    nome: pTexto.target.value,
                                                },
                                            })}
                                        value={this.state.pessoaSolicitante.nome || ''} />
                                </div>

                                <div>
                                    <label>Email</label><br />
                                    <InputText style={{ width: '100%' }}
                                        required
                                        onChange={(pTexto) =>
                                            this.setState({
                                                pessoaSolicitante: {
                                                    ...this.state.pessoaSolicitante,
                                                    emailPlataformaLirida: pTexto.target.value,
                                                },
                                            })}
                                        value={this.state.pessoaSolicitante.emailPlataformaLirida || ''} />
                                </div>

                                <div>
                                    <label>Telefone</label><br />
                                    <InputText style={{ width: '100%' }}
                                        required
                                        onChange={(pTexto) =>
                                            this.setState({
                                                pessoaSolicitante: {
                                                    ...this.state.pessoaSolicitante,
                                                    telefonePlataformaLirida: this.util.formatarTelefone(pTexto.target.value)
                                                },
                                            })}
                                        value={this.state.pessoaSolicitante.telefonePlataformaLirida || ''} />
                                </div>
                            </div>
                        </div>


                        {/* DADOS DA COLETA */}

                        <div> <br />
                            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                                <Button icon="pi pi-home" className="p-button-rounded p-button-info p-button-outlined" aria-label="User" />
                                <h3>Dados da coleta </h3>
                            </div>

                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 20, border: '1px solid lightgray', padding: 20, borderRadius: 3, marginTop: 10 }}>

                                <div>
                                    <label>Empresa</label><br />
                                    <InputText style={{ width: '100%' }}
                                        onChange={(pTexto) =>
                                            this.setState({
                                                documento: {
                                                    ...this.state.documento,
                                                    tags3: pTexto.target.value,
                                                },
                                            })}
                                        value={this.state.documento.tags3 || ''} />
                                </div>

                                <div>
                                    <label>Nome</label><br />
                                    <InputText style={{ width: '100%' }}
                                        required
                                        onChange={(pTexto) =>
                                            this.setState({
                                                pessoaRemetente: {
                                                    ...this.state.pessoaRemetente,
                                                    nome: pTexto.target.value,
                                                },
                                            })}
                                        value={this.state.pessoaRemetente.nome || ''} />
                                </div>

                                <div>
                                    <label>Email</label><br />
                                    <InputText style={{ width: '100%' }}
                                        required
                                        onChange={(pTexto) =>
                                            this.setState({
                                                pessoaRemetente: {
                                                    ...this.state.pessoaRemetente,
                                                    emailPlataformaLirida: pTexto.target.value,
                                                },
                                            })}
                                        value={this.state.pessoaRemetente.emailPlataformaLirida || ''} />
                                </div>

                                <div>
                                    <label>Telefone</label><br />
                                    <InputText style={{ width: '100%' }}
                                        required
                                        onChange={(pTexto) => this.setState({
                                            pessoaRemetente: {
                                                ...this.state.pessoaRemetente,
                                                telefonePlataformaLirida: this.util.formatarTelefone(pTexto.target.value),
                                            },
                                        })
                                        }
                                        value={this.state.pessoaRemetente.telefonePlataformaLirida || ''} />
                                </div>

                                <div>
                                    <label>Data coleta</label><br />
                                    <Calendar dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" style={{ width: '100%' }}
                                        required
                                        onChange={(pTexto) =>
                                            this.setState({
                                                documento: {
                                                    ...this.state.documento,
                                                    dataInicio: this.util.formatarDataBanco(pTexto.target.value),
                                                },
                                            })}
                                        value={this.util.formatarDataCalendar(this.state.documento.dataInicio) || ''} />


                                </div>

                                <div>
                                    <label>Hora prevista coleta</label><br />
                                    <InputText style={{ width: '100%' }}
                                        required
                                        placeholder="00:00"
                                        onChange={(pTexto) =>
                                            this.setState({
                                                documento: {
                                                    ...this.state.documento,
                                                    tags0: pTexto.target.value,
                                                },
                                            })}
                                        value={this.state.documento.tags0} />

                                </div>

                                <div>
                                    <label>Endereço coleta </label><br />
                                    <InputText style={{ width: '310%' }}
                                        required
                                        onChange={(pTexto) =>
                                            this.listarEnderecoGoogleColeta(pTexto.target.value)
                                        }
                                        list="listagoogleColeta"
                                    />

                                    <datalist id="listagoogleColeta">
                                        {this.state.listaEnderecoGoogleColeta.map((item) => (
                                            <option value={item.description}> {item.description}</option>
                                        ))}
                                    </datalist>
                                </div>

                            </div>
                        </div>


                        {/* DADOS DA ENTREGA */}

                        <div> <br />
                            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                                <Button icon="pi pi-car" className="p-button-rounded p-button-info p-button-outlined" aria-label="User" />
                                <h3>Dados da entrega </h3>
                            </div>

                            <div style={{ display: "grid", gridTemplateColumns: " 1fr 1fr 1fr", gap: 20, border: '1px solid lightgray', padding: 20, borderRadius: 3, marginTop: 10 }}>

                                <div>
                                    <label>Empresa</label><br />
                                    <InputText style={{ width: '100%' }}
                                        required
                                        onChange={(pTexto) =>
                                            this.setState({
                                                documento: {
                                                    ...this.state.documento,
                                                    tags4: pTexto.target.value,
                                                },
                                            })}
                                        value={this.state.documento.tags4 || ''} />

                                </div>

                                <div>
                                    <label>Nome</label><br />
                                    <InputText style={{ width: '100%' }}
                                        required
                                        onChange={(pTexto) =>
                                            this.setState({
                                                pessoaDestinatario: {
                                                    ...this.state.pessoaDestinatario,
                                                    nome: pTexto.target.value,
                                                },
                                            })}
                                        value={this.state.pessoaDestinatario.nome || ''} />

                                </div>

                                <div>
                                    <label>Email</label><br />
                                    <InputText style={{ width: '100%' }}
                                        required
                                        onChange={(pTexto) =>
                                            this.setState({
                                                pessoaDestinatario: {
                                                    ...this.state.pessoaDestinatario,
                                                    emailPlataformaLirida: pTexto.target.value,
                                                },
                                            })}
                                        value={this.state.pessoaDestinatario.emailPlataformaLirida || ''} />

                                </div>

                                <div>
                                    <label>Telefone</label><br />
                                    <InputText style={{ width: '100%' }}
                                        required
                                        onChange={(pTexto) =>
                                            this.setState({
                                                pessoaDestinatario: {
                                                    ...this.state.pessoaDestinatario,
                                                    telefonePlataformaLirida: this.util.formatarTelefone(pTexto.target.value),
                                                },
                                            })}
                                        value={this.state.pessoaDestinatario.telefonePlataformaLirida || ''}
                                    />
                                </div>

                                <div>
                                    <label>Data Entrega</label><br />
                                    <Calendar required dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" style={{ width: '100%' }}
                                        onChange={(pTexto) =>
                                            this.setState({
                                                documento: {
                                                    ...this.state.documento,
                                                    dataValidade: this.util.formatarDataBanco(pTexto.target.value),
                                                },
                                            })}
                                        value={this.util.formatarDataCalendar(this.state.documento.dataValidade || '')} />

                                </div>

                                <div>
                                    <label>Hora prevista Entrega</label><br />
                                    <InputText style={{ width: '100%' }}
                                        required
                                        placeholder="00:00"
                                        onChange={(pTexto) =>
                                            this.setState({
                                                documento: {
                                                    ...this.state.documento,
                                                    tags2: pTexto.target.value,
                                                },
                                            })}
                                        value={this.state.documento.tags2 || ''} />

                                </div>

                                <div>
                                    <label>Endereço Entrega </label><br />
                                    <InputText style={{ width: '310%' }}
                                        required
                                        onChange={(pTexto) =>
                                            this.listarEnderecoGoogleEntrega(pTexto.target.value)
                                        }
                                        list="listagoogleEntrega"
                                    />
                                    <datalist id="listagoogleEntrega">
                                        {this.state.listaEnderecoGoogleEntrega.map((item) => (
                                            <option value={item.description}> {item.description}</option>
                                        ))}
                                    </datalist>
                                </div>

                            </div>
                        </div>

                        {/* ITEM */}

                        <div> <br />
                            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                                <Button icon="pi pi-dollar" className="p-button-rounded p-button-info p-button-outlined" aria-label="User" />
                                <h3>Valor</h3>
                            </div>

                            <div style={{ display: "grid", gridTemplateColumns: " 1fr 1fr 1fr", gap: 20, border: '1px solid lightgray', padding: 20, borderRadius: 3, marginTop: 10 }}>
                                {/* <div>
                                    <label>Item</label><br />
                                    <Dropdown style={{ width: '100%', }}
                                        required
                                        onChange={item => this.calculoDistacia(item.target.value)}
                                        value={this.state.value}
                                        options={this.state.listaTabelaPreco}
                                        optionLabel="_itemNome"
                                        placeholder='Selecione' />
                                </div> */}
                                <div>
                                    <label>Transportador</label><br />
                                    <Dropdown style={{ width: '100%', }}
                                        value={this.state.frota}
                                        options={this.state.listaFrota}
                                        optionLabel="_pessoaRelacionadaNome"
                                        onChange={item => this.setState({ frota: item.target.value })}
                                        placeholder='Selecione' />
                                </div>

                                <div>
                                    <label>Valor</label><br />
                                    <InputNumber
                                        onValueChange={pTexto => this.setState({
                                            documento: {
                                                ...this.state.documento, valorTotal: pTexto.target.value
                                            }
                                        })}
                                        mode="currency"
                                        currency="BRL"
                                        locale="pt-BR"
                                        value={this.state.documento.valorTotal}
                                        style={{ width: '100%' }} />
                                </div>

                            </div>
                        </div>


                        {/* ARQUIVO */}

                        <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>

                            <TabView style={{ marginTop: 10 }} >
                                <TabPanel header="Arquivos" disabled={!this.state.documento._seqDocumento}>
                                    {!this.state.documento._seqDocumento ? <div>
                                        <p>Nenhum pedido selecionado / salvo</p>
                                    </div> : <>
                                        <div style={{
                                            marginBottom: 10,
                                            display: "flex",
                                            gap: 20,
                                            flexWrap: 'wrap',
                                            alignItems: 'center'
                                        }}>

                                            <div>
                                                <label>Arquivo</label><br />
                                                <InputText
                                                    type="file"
                                                    onChange={e => this.selecionarArquivo(e.target.files[0])} />
                                            </div>

                                            <div style={{ widht: 'auto', display: 'flex', gap: 20, alignItems: 'end' }}>
                                                <div>
                                                    <label>Nome arquivo</label><br />
                                                    <InputText
                                                        type="Text"
                                                        onChange={pTexto => this.setState({ nomeArquivo: pTexto.target.value })} />

                                                </div>

                                                <div>
                                                    <Button
                                                        label="Salvar arquivo"
                                                        type="button"
                                                        className="p-button-raised"
                                                        loading={this.state.salvarCarregando}
                                                        onClick={() => this.salvarArquivo()}
                                                    />
                                                </div>

                                            </div>


                                        </div>
                                        <DataTable selectionMode="single" showGridlines value={this.state.listaArquivo}>
                                            <Column field="arquivo" header="Arquivo" body={item => <a href={this.state.url_baseArquivo + item.arquivo} target='_blank' rel="noreferrer">{item.arquivo}</a>} />
                                            <Column field="arquivoOriginal" header="Nome arquivo" />
                                            <Column field="_seqDocumentoArquivo" header="Deletar" body={item => <div>
                                                <Button
                                                    onClick={() => this.setState({ vModalDeletarArquivo: true, documentoArquivo: item })}
                                                    icon="pi pi-trash"
                                                    className="p-button-rounded p-button-danger"
                                                    aria-label="Filter" />
                                            </div>
                                            } />
                                        </DataTable>
                                    </>}

                                </TabPanel>

                                {/* ATENDIMENTO */}

                                <TabPanel header="Atendimento" >
                                    {!this.state.documento._seqDocumento ? <div>
                                        <p>Nenhum pedido selecionado / salvo </p>
                                    </div> : <>

                                        <div style={{
                                            widht: 'auto',
                                            display: 'flex',
                                            gap: 20,
                                            justifyContent: 'center',
                                            margin: '10px 0 40px 0'
                                        }}>

                                            <InputTextarea
                                                rows={5}
                                                cols={50}
                                                autoResize
                                                placeholder='Descrição do atendimento'
                                                value={this.state.documentoAtendimento.descricao || ''}
                                                onChange={(pTexto) => this.setState({
                                                    documentoAtendimento: {
                                                        ...this.state.documentoAtendimento, descricao: pTexto.target.value
                                                    }
                                                })}
                                            />

                                            <div>
                                                <Button
                                                    label="Salvar"
                                                    type="button"
                                                    className="p-button-raised"
                                                    loading={this.state.salvarCarregando}
                                                    onClick={() => this.salvarAtendimento()}
                                                />
                                            </div>

                                            {this.state.documentoAtendimento._seqDocumentoAtendimento && <div><Button loading={this.state.deletarCarregando}
                                                label="Deletar"
                                                className="p-button-danger"
                                                onClick={() => this.setState({ vModalDeletarAtendimento: true, })} /></div>}

                                        </div>

                                        <DataTable
                                            selectionMode="single"
                                            showGridlines
                                            removableSort
                                            value={this.state.listaDocumentoAtendimento}
                                            onSelectionChange={e => this.selecionarDocumentoAtendimento(e.value)}
                                        >
                                            <Column field="descricao" header="Descrição" />
                                            <Column field="dataCadastro" header="Data cadastro" sortable body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>
                                            } />
                                        </DataTable>

                                    </>}



                                </TabPanel>

                                <TabPanel header="Relacionamento" >

                                    <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                                        <h2 style={{ fontSize: 22.5 }}>Relacionamento</h2>
                                        <DataTable selectionMode="single" showGridlines value={this.state.listaRelacionamento} onSelectionChange={e => this.selecionar(e.value)}>
                                            <Column field="_pessoaRelacionadaNome" header="Nome"></Column>
                                            <Column field="_tipoRelacionamentoNome" header="Tipo de relacionamento"></Column>
                                            <Column field="dataCadastro" header="data de cadastro"></Column>
                                        </DataTable>
                                    </div>

                                </TabPanel>


                            </TabView>

                        </div>
                    </main>
                </form>

            }
        </div >
    }
}