import { Component, createRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Navigate } from "react-router-dom";
import { Messages } from "primereact/messages";

import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";

import LogoRecuperarSenha from "../assets/imagens/cadastro.png"
import LogoCross from "../assets/imagens/headerLogo.png"


import Constante from "../util/Constante";
import Util from "../util/util";



export default class RecuperarSenhaPg extends Component {

    mensagens = createRef();
    util = new Util();
    constante = new Constante()
    PessoaService = new PessoaService(this.constante.token, this.constante.url_base)


    state = {
        pessoaUsuario: new Pessoa(),
        carregando: false,
        
        liberarAcesso: {
            login: false,
        },
    }

    async RecuperarSenha() {
        this.pessoaUsuario = this.state.pessoaUsuario
        console.log('pessoa usuario')
        console.log(this.pessoaUsuario);

        this.setState({ carregando: true });

        if (!this.pessoaUsuario.emailPlataformaLirida) {

            setTimeout(() => {
                this.setState({ carregando: false, });
                this.mensagens.current.show([
                    { severity: 'error', summary: 'Email nao informado', sticky: true },
                ]);
            }, 1000);

            return
        }

        this.listaPesquisa = [
            this.util.criarObjetoPesquisa(
                "pessoa.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa.email_plataforma_lirida",
                this.pessoaUsuario.emailPlataformaLirida,
                "AND",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa.seq_pessoa_perfil",
                this.constante.seqPerfilOperacional,
                "AND",
                "TEXTO"
            ),
        ]

        this.retornoListaPesquisa = await this.PessoaService.listarComFiltro(this.listaPesquisa)
        console.log("retorno da pesquisa", this.retornoListaPesquisa.objeto[0])
        this.pessoa = this.retornoListaPesquisa.objeto[0]
        console.log("pessoa", this.pessoa);

        if (!this.retornoListaPesquisa.objeto[0]) {

            setTimeout(() => {
                this.setState({ carregando: false, });
                this.mensagens.current.show([
                    { severity: 'error', summary: 'Email invalido', sticky: true },
                ]);
            }, 1000);
            return
        }


        else if (this.pessoa.emailPlataformaLirida === this.pessoaUsuario.emailPlataformaLirida) {

            await this.util.enviarEmail(this.constante.seqEmail, this.pessoaUsuario.emailPlataformaLirida, this.pessoa.nome, this.pessoa.senha)

            setTimeout(() => {
                this.setState({ carregando: false, });
                this.mensagens.current.show([
                    { severity: 'success', summary: 'Sua senha foi enviada para o seu email !!', sticky: true },
                ]);

            }, 1000);
        }
    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }

    render() {
        return <div>
            {/* NAVEGAÇÃO */}
            {this.state.liberarAcesso.login && <Navigate to="login" />}

            {/* TELA */}
            <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'var(--primary-color)', padding: 20 }}>
                <img src={LogoCross} alt="logo" style={{ height: 60 }} />
            </header>

            <main style={{ width: '90%', margin: '80px auto', display: 'flex', alignItems: 'center', gap: 150 }}>
                <img src={LogoRecuperarSenha} alt="Crosslog" style={{ width: 350, objectFit: 'contain' }} />

                <form onSubmit={e => { e.preventDefault(); this.RecuperarSenha(e.target) }} style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <Messages ref={this.mensagens} />

                    <h1>Recuperar senha</h1>

                    <div>
                        <label>Email</label><br />
                        <InputText
                            style={{ width: '100%'}}
                            onChange={pTexto => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario, emailPlataformaLirida: pTexto.target.value
                                }
                            })} />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button label="Login" type="button" className="p-button-link" style={{ padding: 0 }} onClick={() => this.navegar("login")} />
                        <Button label="Enviar" type="submit" loading={this.state.carregando} />
                    </div>
                </form>
            </main>
        </div>
    }
}