import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Component } from "react";
import { Navigate } from "react-router-dom";
import { Dialog } from 'primereact/dialog';

import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService"
import DocumentoItem from "lirida-back-service/Servicos/Documento/DocumentoItem";
import DocumentoArquivo from 'lirida-back-service/Servicos/DocumentoArquivo/DocumentoArquivo';
import DocumentoAtendimento from 'lirida-back-service/Servicos/DocumentoAtendimento/DocumentoAtendimento';


import Constante from "../util/Constante";
import Util from "../util/util";
import { TabPanel, TabView } from "primereact/tabview";
import { InputTextarea } from "primereact/inputtextarea";

export default class DetalheDocumentopg extends Component {

    util = new Util()
    constante = new Constante();

    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);

    state = {
        liberarAcesso: {
            documento: false,
        },
        salvarCarregando: false,
        deletarCarregando: false,
        vModalDeletar: false,


        //STATE ARQUIVO
        documentoArquivo: new DocumentoArquivo(),
        nomeArquivo: '',
        tipoArquivo: '',
        base64Arquivo: '',
        url_baseArquivo: '',
        listaArquivo: [],
        vModalDeletarArquivo: false,


        //STATE ATENDIMENTO
        documentoAtendimento: new DocumentoAtendimento(),
        listaDocumentoAtendimento: [],
        vModalDeletarAtendimento: false,

    }

    componentDidMount() {
        this.iniciar()
        // console.log(this.props.documentoDetalhe)
        // this.listarArquivo(this.props.documentoItemDetalhe);

    }

    async iniciar() {
        this.pessoaUsuario = this.state.pessoaUsuario
        this.url_baseArquivo = await this.util.buscarUrlBaseArquivo();
        this.retornoLocalStorage = await this.util.buscarLocalStorage('PESSOA_USUARIO')
        console.log("USUARIO LOCALSTORAGE", this.retornoLocalStorage)
        this.pessoaUsuario = this.retornoLocalStorage

        this.listarArquivo(this.props.documentoDetalhe);
        this.listarDocumentoAtendimento(this.props.documentoDetalhe)

    }


    // ARQUIVO //

    async selecionarArquivo(pArquivo) {
        console.log(pArquivo.name);
        this.tipoArquivo = String(pArquivo.name).split('.').slice(-1)[0];
        console.log(this.tipoArquivo);
        const retorno = await this.util.pegarBase64(pArquivo);
        console.log(retorno)
        this.setState({ base64Arquivo: retorno, tipoArquivo: this.tipoArquivo });
    }

    async salvarArquivo() {
        this.setState({ salvarCarregando: true })
        this.documento = this.props.documentoDetalhe;
        console.log("=========", this.documento);

        this.base64Arquivo = this.state.base64Arquivo;
        this.nomeArquivo = this.state.nomeArquivo;
        this.tipoArquivo = this.state.tipoArquivo;
        this.nomeArquivo = this.nomeArquivo + '.' + this.tipoArquivo

        this.documentoArquivo = new DocumentoArquivo();
        this.documentoArquivo.seqDocumento = this.documento.seqDocumento;
        this.documentoArquivo.seqUsuario = this.constante.seqUsuario;
        this.documentoArquivo.situacao = 'ATIVO';
        this.documentoArquivo.arquivo = this.base64Arquivo;
        this.documentoArquivo.arquivoOriginal = this.nomeArquivo;
        this.documentoArquivo.seqTipoArquivo = this.constante.seqTipoArquivoInformacoesAdicionais;
        this.retornoArquivo = await this.documentoService.salvarArquivo(this.documentoArquivo);
        console.log("Arquivo")
        console.log(this.retornoArquivo);

        this.listarArquivo(this.props.documentoDetalhe);

        this.setState({
            salvarCarregando: false,
            base64Arquivo: '',
            nomeArquivo: '',
            tipoArquivo: '',
        })

    }

    async listarArquivo(pDocumento) {
        this.documento = pDocumento
        console.log(this.documento);
        
        // //buscando documento
        // this.retornoDocumento = await this.documentoService.buscar(this.constante.seqUsuario, this.documento.seqDocumento)
        // this.documento = this.retornoDocumento.objeto
        // console.log("documento Arquivo buscado", this.documento);

        //listando documento
        this.retornoListaArquivo = await this.documentoService.listarArquivo(this.documento);
        this.listaArquivo = this.retornoListaArquivo.objeto;

        console.log("lista arquivoooooooooo", this.listaArquivo);

        this.setState({ listaArquivo: this.listaArquivo });

    }

    async deletarArquivo() {
        this.documentoArquivo = this.state.documentoArquivo;
        await this.documentoService.deletarArquivo(this.documentoArquivo);
        this.listarArquivo(this.props.documentoDetalhe);

    }




    // ATENDIMENTO //

    async salvarAtendimento() {
        this.setState({ salvarCarregando: true })
        this.documento = this.props.documentoDetalhe;
        console.log("@@@@@ atendimento", this.documento);


        this.documentoAtendimento = this.state.documentoAtendimento;
        this.documentoAtendimento.seqUsuario = this.constante.seqUsuario;
        this.documentoAtendimento.seqTipoAtendimento = this.constante.seqTipoAtendimentoInformacoesAdicionais;
        this.documentoAtendimento.seqDocumento = this.documento.seqDocumento;
        this.documentoAtendimento.situacao = 'ATIVO';
        this.retorno = await this.documentoService.salvarAtendimento(this.documentoAtendimento);
        console.log(this.retorno);
        this.setState({
            salvarCarregando: false,
            documentoAtendimento: new DocumentoAtendimento()
        })
        this.listarDocumentoAtendimento(this.props.documentoDetalhe);
    } 

    async listarDocumentoAtendimento(pDocumento) {
        this.documento = pDocumento

        // //buscando documento 
        // this.retornoDocumento = await this.documentoService.buscar(this.constante.seqUsuario, this.documentoItem.seqDocumento)
        // this.documento = this.retornoDocumento.objeto
        // console.log("documento Atendimento buscado", this.documento);

        //listando atendimento
        this.retornoAtendimento = await this.documentoService.listarAtendimento(this.documento);
        this.listaDocumentoAtendimento = this.retornoAtendimento.objeto
        console.log("lista atendimento", this.listaDocumentoAtendimento);
        this.setState({
            listaDocumentoAtendimento: this.listaDocumentoAtendimento
        })
    }

    selecionarDocumentoAtendimento(e) {
        console.log(e)
        this.setState({ documentoAtendimento: e });
    }

    async deletarDocumentoAtendimento() {
        this.documentoAtendimento = this.state.documentoAtendimento;
        await this.documentoService.deletarAtendimento(this.documentoAtendimento);
        console.log("ATENDIMENTO DELETADO")
        this.setState({ documentoAtendimento: new DocumentoAtendimento() });
        this.listarDocumentoAtendimento(this.props.documentoDetalhe)

    }






    salvar() {
        this.setState({ salvarCarregando: true });
        setTimeout(() => this.setState({ salvarCarregando: false, salvarCheck: true, }), 2000);
        setTimeout(() => this.setState({ salvarCheck: false, }), 4000);
    }

    deletar() {
        this.setState({ deletarCarregando: true });
        setTimeout(() => this.setState({ deletarCarregando: false, }), 2000);
    }

    navegar() {
        this.props.setState({ telaDetalhe: false })
        this.props.iniciar()
    }

    render() {
        return <div>
            {/* NAVEGAÇÃO */}
            {this.state.liberarAcesso.documento && <Navigate to="/documento" />}

            <Dialog header="Alerta" visible={this.state.vModalDeletar} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletar: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletar();
                            this.setState({ vModalDeletar: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletar: false, })} />
                    </div>
                </div>
            </Dialog>


            <Dialog header="Alerta" visible={this.state.vModalDeletarArquivo} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletarArquivo: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletarArquivo();
                            this.setState({ vModalDeletarArquivo: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletarArquivo: false, })} />
                    </div>
                </div>
            </Dialog>


            <Dialog header="Alerta" visible={this.state.vModalDeletarAtendimento} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletarAtendimento: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletarDocumentoAtendimento();
                            this.setState({ vModalDeletarAtendimento: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletarAtendimento: false, })} />
                    </div>
                </div>
            </Dialog>

            {/* TELA */}
            {/* <Header /> */}

            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <Button label={<i className="pi pi-arrow-left"></i>} className="p-button-raised" onClick={() => this.navegar()} />
                        <div>
                            <h1 style={{ fontSize: 22 }}>Detalhe do documento</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Detalhe do documento</h2>
                        </div>
                        
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                        
                    <Button loading={this.state.deletarCarregando} label="salvar" className="p-button-raised"  />
                    <Button loading={this.state.deletarCarregando} label="gerar cobrança" className="p-button-raised" />
                    </div>
                </div>


                <div style={{ display: "grid", gridTemplateColumns: " 1fr 1fr", gap: 20, border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                    <div>
                        <label>Solicitate</label><br />
                        <InputText value={this.props.documentoDetalhe._pessoaNome} disabled style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Valor</label><br />
                        <InputText value={this.props.documentoDetalhe.valorTotal} disabled style={{ width: '100%' }} />
                    </div>

                    <div>
                        <label>responsavel Coleta</label><br />
                        <InputText value={this.props.responsavelColeta} disabled style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>responsavel Entrega</label><br />
                        <InputText value={this.props.responsavelEntrega} disabled style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Empresa coleta</label><br />
                        <InputText value={this.props.documentoDetalhe.tags3} disabled style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Empresa entrega</label><br />
                        <InputText value={this.props.documentoDetalhe.tags4} disabled style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Data Coleta</label><br />
                        <InputText value={this.util.formatarData(this.props.documentoDetalhe.dataProgramada)} disabled style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Data Entrega</label><br />
                        <InputText value={this.util.formatarData(this.props.documentoDetalhe.dataValidade)} disabled style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Hora coleta</label><br />
                        <InputText value={this.props.documentoDetalhe.tags0} disabled style={{ width: '100%' }} />
                    </div>

                    <div>
                        <label>Hora entrega</label><br />
                        <InputText value={this.props.documentoDetalhe.tags2} disabled style={{ width: '100%' }} />
                    </div>
                    <div>
                        <label>Endereço coleta </label><br />
                        <InputText disabled style={{ width: '100%' }}
                            value={this.props.enderecoColetaDetalhe.logradouro + ', ' + this.props.enderecoColetaDetalhe.numero + ','
                                + this.props.enderecoColetaDetalhe.bairro + ', ' + this.props.enderecoColetaDetalhe.cidade} />
                    </div>
                    <div>
                        <label>Endereço coleta </label><br />
                        <InputText disabled style={{ width: '100%' }}
                            value={this.props.enderecoEntregaDetalhe.logradouro + ', ' + this.props.enderecoEntregaDetalhe.numero + ','
                                + this.props.enderecoEntregaDetalhe.bairro + ', ' + this.props.enderecoEntregaDetalhe.cidade} />
                    </div>
                </div>

                {/* ARQUIVO */}

                <TabView style={{ marginTop: 10 }} >

                    <TabPanel header="Arquivos" >
                        <div style={{
                            marginBottom: 10,
                            display: "flex",
                            gap: 20,
                            flexWrap: 'wrap',
                            alignItems: 'center'
                        }}>

                            <div>
                                <label>Arquivo</label><br />
                                <InputText
                                    type="file"
                                    onChange={e => this.selecionarArquivo(e.target.files[0])} />
                            </div>


                            <div style={{ widht: 'auto', display: 'flex', gap: 20, alignItems: 'end' }}>
                                <div>
                                    <label>Nome arquivo</label><br />
                                    <InputText
                                        type="Text"
                                        onChange={pTexto => this.setState({ nomeArquivo: pTexto.target.value })} />

                                </div>

                                <div>
                                    <Button
                                        label="Salvar arquivo"
                                        type="button"
                                        className="p-button-raised"
                                        loading={this.state.salvarCarregando}
                                        onClick={() => this.salvarArquivo()}
                                    />
                                </div>

                            </div>

                        </div>

                        <DataTable selectionMode="single" showGridlines value={this.state.listaArquivo}>
                            <Column field="arquivo" header="Arquivo" body={item => <a href={this.state.url_baseArquivo + item.arquivo} target='_blank' rel="noreferrer">{item.arquivo}</a>} />
                            <Column field="arquivoOriginal" header="Nome arquivo" />
                            <Column field="_seqDocumentoArquivo" header="Deletar" body={item => <div>
                                <Button
                                    onClick={() => this.setState({ vModalDeletarArquivo: true, documentoArquivo: item })}
                                    icon="pi pi-trash"
                                    className="p-button-rounded p-button-danger"
                                    aria-label="Filter" />
                            </div>
                            } />
                        </DataTable>
                    </TabPanel>

                    <TabPanel header="Atendimento" >
                        <div style={{
                            widht: 'auto',
                            display: 'flex',
                            gap: 20,
                            justifyContent: 'center',
                            margin: '10px 0 40px 0'
                        }}>
                            <InputTextarea
                                rows={5}
                                cols={50}
                                autoResize
                                placeholder='Descrição do atendimento'
                                value={this.state.documentoAtendimento.descricao || ''}
                                onChange={(pTexto) => this.setState({
                                    documentoAtendimento: {
                                        ...this.state.documentoAtendimento, descricao: pTexto.target.value
                                    }
                                })}
                            />

                            <div>
                                <Button
                                    label="Salvar"
                                    type="button"
                                    className="p-button-raised"
                                    loading={this.state.salvarCarregando}
                                    onClick={() => this.salvarAtendimento()}
                                />
                            </div>

                            {this.state.documentoAtendimento._seqDocumentoAtendimento && <div><Button loading={this.state.deletarCarregando}
                                label="Deletar"
                                className="p-button-danger"
                                onClick={() => this.setState({ vModalDeletarAtendimento: true, })} /></div>}
                        </div>

                        <DataTable
                            selectionMode="single"
                            showGridlines
                            removableSort
                            value={this.state.listaDocumentoAtendimento}
                            onSelectionChange={e => this.selecionarDocumentoAtendimento(e.value)}
                        >
                            <Column field="descricao" header="Descrição" />
                            <Column field="dataCadastro" header="Data cadastro" sortable body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>
                            } />
                        </DataTable>

                    </TabPanel>
                </TabView>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                    <h2 style={{ fontSize: 22.5 }}>Relacionamento</h2>
                    <DataTable selectionMode="single" showGridlines value={this.props.listaRelacionamento} onSelectionChange={e => this.selecionar(e.value)}>
                        <Column field="_pessoaRelacionadaNome" header="Nome"></Column>
                        <Column field="_tipoRelacionamentoNome" header="Tipo de relacionamento"></Column>
                        <Column field="dataCadastro" header="data de cadastro"></Column>
                    </DataTable>




                    {/* <TabView style={{ marginTop: 10 }}>
                        <TabPanel header="Tab 1">
                            Conteudo qualquer 1
                        </TabPanel>
                        <TabPanel header="Tab 2">
                            Conteudo qualquer 2
                        </TabPanel>
                        <TabPanel header="Tab 3">
                            Conteudo qualquer 3
                        </TabPanel>
                    </TabView> */}
                </div>
            </main>
        </div >
    }
}