import { Component } from "react";
import { Navigate } from "react-router-dom";
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';

import LogoCross from "../assets/imagens/headerLogo.png"

import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";

import Constante from "../util/Constante";
import Util from "../util/util";



export default class Header extends Component {

    constante = new Constante()
    util = new Util()

    state = {
        liberarAcesso: {
            documento: false,
            financeiro: false,
            perfil: false,
            login: false,
            frota:false
        },

        urlBase: '',
        pessoaUsuario: new Pessoa()
    }

    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {
        const urlBase = await this.util.buscarUrlBaseArquivo();
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        this.setState({
            urlBase: urlBase,
            pessoaUsuario: pessoaUsuario,
        })
    }


    navegar(pTela) {
        const url_path = window.location.pathname.split("/")[1];
        console.log(pTela, url_path)
        if (pTela === url_path) return;
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }



    sair() {
        const liberarAcesso = this.state.liberarAcesso;

        liberarAcesso.login = true;

        this.util.removerLocalStorage("PESSOA_USUARIO");

        this.setState({ liberarAcesso: liberarAcesso });
    }

    render() {
        return <>
            {/* NAVEGAÇÃO */}
            {this.state.liberarAcesso.documento && <Navigate to="/documento" />}
            {this.state.liberarAcesso.financeiro && <Navigate to="/financeiro" />}
            {this.state.liberarAcesso.perfil && <Navigate to="/perfil" />}
            {this.state.liberarAcesso.login && <Navigate to="/login" />}
            {this.state.liberarAcesso.frota && <Navigate to="/frota" />}

            {/* COMPONENTE */}
            <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'var(--primary-color)', padding: 10 }}>
                <img src={LogoCross} alt="logo" style={{ height: 60 }} />

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <nav style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 5 }}>
                        <Button label="Documento" onClick={() => this.navegar("documento")} />
                        <Button label="Minha Frota" onClick={() => this.navegar("frota")} />
                        <Button label="Financeiro" onClick={() => this.navegar("financeiro")} />
                        <Button style={{ padding: 0 }} label={
                            <Avatar image={this.state.urlBase + this.state.pessoaUsuario.foto} shape="circle"></Avatar>
                        } onClick={() => this.navegar("perfil")} />
                        <Button label="Sair" icon={<i className="pi pi-sign-out"></i>} iconPos="right" style={{ gap: 5 }} onClick={() => this.navegar("login")} />
                    </nav>
                </div>
            </header>
        </>
    }
}