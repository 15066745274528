import { Component, createRef } from "react";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Header from "../components/Header";
import { Dropdown } from 'primereact/dropdown';
import { TabPanel, TabView } from "primereact/tabview";
import { Messages } from "primereact/messages";

import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import CepService from "lirida-back-service/Servicos/Cep/CepService";

import Constante from "../util/Constante";
import Util from "../util/util";

class Banco {
    _codigo;
    _nome;
    _seqBanco;
}

export default class PerfilPg extends Component {

    mensagens = createRef();
    constante = new Constante();
    util = new Util(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base)
    cepService = new CepService(this.constante.token, this.constante.url_base);


    state = {
        pessoaUsuario: new Pessoa(),
        urlBase: '',
        senhaAtual: "",
        novaSenha: "",
        confirmarSenha: "",
        base64: '',
        banco: new Banco(),
        listaBanco: [],
        conteudo: '',
        endereco: new Endereco(),
        carregando: false,


    }



    componentDidMount() {

        this.iniciar()

    }

    async iniciar() {

        const urlBase = await this.util.buscarUrlBaseArquivo();
        this.pessoaUsuario = this.state.pessoaUsuario
        this.retornoLocalStorage = await this.util.buscarLocalStorage('PESSOA_USUARIO')
        this.pessoaUsuario = this.retornoLocalStorage
        console.log("usuario localStorage", this.pessoaUsuario);
        const listaBanco = await this.util.listarBanco(this.state.conteudo);
        let banco = this.state.banco;

        if (!this.pessoaUsuario) {
            this.setState({ liberarAcessoTelaLogin: true })
        }


        if (this.pessoaUsuario.contaCodigoBanco) {
            for (let i = 0; i < listaBanco.length; i++) {
                if (listaBanco[i]._codigo === this.pessoaUsuario.contaCodigoBanco) {
                    banco = listaBanco[i];
                }
            }
        }

        this.retorno = await this.pessoaService.listarEndereco(this.pessoaUsuario);
        this.listaEndereco = this.retorno.objeto;

        for (let i = 0; i < this.listaEndereco.length; i++) {
            if (this.pessoaUsuario.seqEnderecoPrincipal === this.listaEndereco[i]._seqEndereco) {
                this.endereco = this.listaEndereco[i];
            }
        }

        this.setState({
            pessoaUsuario: this.pessoaUsuario,
            urlBase: urlBase,
            banco: banco,
            listaBanco: listaBanco,
            endereco: this.endereco,
        })
    }






    async salvarDadosPessoais() {
        this.setState({ carregando: true, });

        this.pessoaUsuario = this.state.pessoaUsuario

        if (this.state.base64) {
            this.pessoaUsuario.foto = this.state.base64
        }

        this.retornoPessoa = await this.pessoaService.salvar(this.pessoaUsuario)
        this.pessoaUsuario = this.retornoPessoa.objeto
        console.log('pessoa', this.retornoPessoa.objeto);


        // salvando endereco
        this.endereco = this.state.endereco
        this.endereco.seqUsuario = this.constante.seqUsuario;
        this.endereco.seqPessoa = this.pessoaUsuario._seqPessoa;
        this.endereco.seqTipoEndereco = this.constante.seqTipoEnderecoCobranca;

        this.retornoEndereco = await this.pessoaService.salvarEndereco(this.endereco);
        this.endereco = this.retornoEndereco.objeto;

        this.pessoaUsuario.seqEnderecoPrincipal = this.endereco._seqEndereco;

        this.retornoPessoaEndereco = await this.pessoaService.salvar(this.pessoaUsuario);
        this.pessoaUsuario = this.retornoPessoaEndereco.objeto;

        console.log(this.pessoaUsuario);

        this.util.salvarLocalStorage("PESSOA_USUARIO", this.pessoaUsuario)

        this.mensagens.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!', sticky: true },
        ]);

        this.setState({
            pessoaUsuario: this.pessoaUsuario,
            carregando: false
        })
    }


    async salvarContaBanco() {
        this.setState({ carregando: true, });

        this.pessoaUsuario = this.state.pessoaUsuario;

        this.retorno = await this.pessoaService.salvar(this.pessoaUsuario);
        this.pessoaUsuario = this.retorno.objeto;

        this.retorno = await this.pessoaService.salvarContaBanco(this.pessoaUsuario);
        this.pessoaUsuario = this.retorno.objeto;

        this.util.salvarLocalStorage("PESSOA_USUARIO",this.pessoaUsuario);

        console.log(this.pessoaUsuario);

        this.setState({
            pessoaUsuario: this.pessoaUsuario,
            carregando: false,
        });
    }

    async alterarSenha() {
        this.setState({carregando:true})
        this.pessoaUsuario = this.state.pessoaUsuario

        if (this.state.base64) {
            this.pessoaUsuario.foto = this.state.base64
        }

        if (this.state.senhaAtual !== this.pessoaUsuario.senha) {
            alert("senha atual está incorreta")
            return
        }
        else if (this.state.novaSenha !== this.state.confirmarSenha) {
            alert("Nova senha e confirmar senha não coincide")
            return
        }

        this.pessoaUsuario.senha = this.state.confirmarSenha;
        this.retornoPessoa = await this.pessoaService.salvar(this.pessoaUsuario)
        alert("sua senha foi alterada com sucesso!!")
        this.util.salvarLocalStorage("PESSOA_USUARIO", this.pessoaUsuario)
        
        this.setState({carregando:false})
    }


    async selecionarArquivo(pAquivo) {
        const retorno = await this.util.pegarBase64(pAquivo);
        console.log(retorno);
        this.setState({ base64: retorno });
    }


    async buscarCEP(pCep) {
        const endereco = this.state.endereco;

        const retorno = await this.cepService.buscarCep(pCep);

        endereco.bairro = retorno.bairro;
        endereco.complemento = retorno.complemento;
        endereco.cidade = retorno.localidade;
        endereco.estado = retorno.estado;
        endereco.logradouro = retorno.logradouro;
        endereco.uf = retorno.uf;

        this.setState({ endereco: endereco });
    }


    render() {
        return <div>
            <Header />

            <main style={{ maxWidth: '90%', width: 500, margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20, padding: '20px 0 200px 0' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20, marginTop: 20 }}>
                    <Avatar style={{ width: 200, height: 200, objectFit: "cover" }} shape="circle" image={this.state.urlBase + this.state.pessoaUsuario.foto} />
                    <InputText type="file" onChange={e => this.selecionarArquivo(e.target.files[0])} />

                </div>

                <TabView style={{ maxWidth: '90%', width: 500, margin: '0 auto' }}>
                    <TabPanel header="Dados pessoais" style={{ maxWidth: '90%', width: 500, margin: '0 auto' }}>
                        <form style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}
                            onSubmit={(event) => this.salvarDadosPessoais(event.preventDefault())}>

                            <Messages ref={this.mensagens} />

                            <div>
                                <label>Nome</label><br />
                                <InputText value={this.state.pessoaUsuario.nome} disabled style={{ width: '100%' }} />
                            </div>

                            <div>
                                <label>Email</label><br />
                                <InputText style={{ width: '100%' }}
                                    onChange={pTexto => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario, emailPlataformaLirida:
                                                pTexto.target.value
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.emailPlataformaLirida} />
                            </div>
                            <div>
                                <label>Telefone</label><br />
                                <InputText style={{ width: '100%' }}
                                    onChange={pTexto => this.setState({
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario, telefonePlataformaLirida:
                                                this.util.formatarTelefone(pTexto.target.value)
                                        }
                                    })}
                                    value={this.state.pessoaUsuario.telefonePlataformaLirida} />
                            </div>

                            <div>
                                <label>Documento</label><br />
                                <InputText value={this.state.pessoaUsuario.documento} disabled style={{ width: '100%' }} />
                            </div>

                            <div>
                                <label>CEP</label><br />
                                <div className="p-inputgroup">
                                    <InputText
                                        type="tel"
                                        style={{ width: '100%' }}
                                        onChange={pTexto => this.setState({
                                            endereco: {
                                                ...this.state.endereco,
                                                cep: this.util.formatarCEP(pTexto.target.value),
                                            }
                                        })}
                                        value={this.state.endereco.cep} />
                                    <Button
                                        icon="pi pi-search"
                                        type="button"
                                        onClick={() => this.buscarCEP(this.state.endereco.cep)} />
                                </div>
                            </div>

                            <div style={{ width: "100%", display: "flex", gap: 5, alignItens: "center" }}>
                                <div>
                                    <label>Logradouro</label><br />
                                    <InputText style={{ width: '100%' }}
                                        onChange={pTexto => this.setState({
                                            endereco: {
                                                ...this.state.endereco, logradouro: pTexto.target.value
                                            }
                                        })}
                                        value={this.state.endereco.logradouro} />


                                </div>
                                <div>
                                    <label>Numero</label><br />
                                    <InputText style={{ width: '100%' }}
                                        onChange={pTexto => this.setState({
                                            endereco: {
                                                ...this.state.endereco, numero: pTexto.target.value
                                            }
                                        })}
                                        value={this.state.endereco.numero} />

                                </div>
                            </div>

                            <div>
                                <label>Bairro</label><br />
                                <InputText style={{ width: '100%' }}
                                    onChange={pTexto => this.setState({
                                        endereco: {
                                            ...this.state.endereco, bairro: pTexto.target.value
                                        }
                                    })}
                                    value={this.state.endereco.bairro} />
                            </div>

                            <div>
                                <label>Complemento</label><br />
                                <InputText style={{ width: '100%' }}
                                    onChange={pTexto => this.setState({
                                        endereco: {
                                            ...this.state.endereco, complemento: pTexto.target.value
                                        }
                                    })}
                                    value={this.state.endereco.complemento} />
                            </div>
                            <div style={{ width: "100%", display: "flex", gap: 5, alignItens: "center" }}>

                                <div>
                                    <label>Cidade</label><br />
                                    <InputText style={{ width: '100%' }}
                                        onChange={pTexto => this.setState({
                                            endereco: {
                                                ...this.state.endereco, cidade: pTexto.target.value
                                            }
                                        })}
                                        value={this.state.endereco.cidade} />
                                </div>
                                <div>
                                    <label>Uf</label><br />
                                    <InputText style={{ width: '100%' }}
                                        onChange={pTexto => this.setState({
                                            endereco: {
                                                ...this.state.endereco, uf: pTexto.target.value
                                            }
                                        })}
                                        value={this.state.endereco.uf} />
                                </div>
                            </div>

                            <div>
                                <Button loading={this.state.carregando} type="submit" label="Salvar" />
                            </div>
                        </form>
                    </TabPanel>
                    <TabPanel header="Dados bancários" style={{ maxWidth: '90%', width: 500, margin: '0 auto' }}>
                        <form style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}
                            onSubmit={(event) => this.salvarContaBanco(event.preventDefault())}>

                            <div>
                                <label>Banco</label><br />
                                <Dropdown
                                    style={{ width: '100%' }}
                                    options={this.state.listaBanco}
                                    optionLabel="_nome"
                                    value={this.state.banco}
                                    onChange={e => this.setState({
                                        banco: e.value,
                                        pessoaUsuario: {
                                            ...this.state.pessoaUsuario,
                                            contaCodigoBanco: e.value._codigo
                                        }
                                    })} />
                            </div>

                            <div style={{ width: "100%", display: "flex", gap: 5, alignItens: "center" }}>
                                <div>
                                    <label>Conta</label><br />
                                    <InputText
                                        onChange={pTexto => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario, contaNumeroConta:
                                                    pTexto.target.value
                                            }
                                        })}
                                        value={this.state.pessoaUsuario.contaNumeroConta} />
                                </div>

                                <div>
                                    <label>Digito da Conta</label><br />
                                    <InputText style={{ width: '100%' }}
                                        onChange={pTexto => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario, contaDigitoConta:
                                                    pTexto.target.value
                                            }
                                        })}
                                        value={this.state.pessoaUsuario.contaDigitoConta} />
                                </div>
                            </div>


                            <div style={{ width: "100%", display: "flex", gap: 5, alignItens: "center" }}>
                                <div>
                                    <label>Agencia</label><br />
                                    <InputText
                                        onChange={pTexto => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario, contaAgencia:
                                                    pTexto.target.value
                                            }
                                        })}
                                        value={this.state.pessoaUsuario.contaAgencia} />

                                </div>

                                <div>
                                    <label>Digito da Agencia</label><br />
                                    <InputText style={{ width: '100%' }}
                                        onChange={pTexto => this.setState({
                                            pessoaUsuario: {
                                                ...this.state.pessoaUsuario, contaDigitoAgencia:
                                                    pTexto.target.value
                                            }
                                        })}
                                        value={this.state.pessoaUsuario.contaDigitoAgencia} />

                                </div>
                            </div>
                            {this.state.pessoaUsuario.contaS2pCodigo &&
                                <div style={{ display: 'flex', gap: 10 }}>
                                    <i className="pi pi-check" style={{ color: "green", fontSize: 20 }}></i>
                                    <p>Conta cadastrada</p>
                                </div>
                            }
                            <div>
                                <Button loading={this.state.carregando} type="submit" label="Salvar" />
                            </div>
                        </form>
                    </TabPanel>
                    <TabPanel header="Alterar senha" style={{ maxWidth: '90%', width: 500, margin: '0 auto' }}>
                        <form style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}
                            onSubmit={(event) => this.alterarSenha(event.preventDefault())}>
                            <div>
                                <label>senha atual</label><br />
                                <InputText style={{ width: '100%' }}
                                    onChange={pTexto => this.setState({ senhaAtual: pTexto.target.value })}
                                />

                            </div>

                            <div>
                                <label> Nova senha </label><br />
                                <InputText style={{ width: '100%' }}
                                    onChange={pTexto => this.setState({ novaSenha: pTexto.target.value })}
                                />
                            </div>

                            <div>
                                <label>Confirmar nova senha </label><br />
                                <InputText style={{ width: '100%' }}
                                    onChange={pTexto => this.setState({ confirmarSenha: pTexto.target.value })}

                                />
                            </div>
                            <div>
                            <Button loading={this.state.carregando} type="submit" label="Salvar" />

                            </div>
                        </form>
                    </TabPanel>
                </TabView>
            </main>
        </div>
    }
}