import { Component, createRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Navigate } from "react-router-dom";
import { Messages } from "primereact/messages";

import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";

import LogoAzul from "../assets/imagens/logoAzul.jpg"
import LogoCross from "../assets/imagens/headerLogo.png"


import Constante from "../util/Constante";
import Util from "../util/util";



export default class LoginPg extends Component {
    mensagens = createRef();
    util = new Util();
    constante = new Constante()
    PessoaService = new PessoaService(this.constante.token, this.constante.url_base)


    state = {
        pessoaUsuario: new Pessoa(),
        carregando: false,


        liberarAcesso: {
            documento: false,
            recuperarSenha: false,
            cadastro: false,
        },
    }

    async validarAcesso() {
        this.pessoaUsuario = this.state.pessoaUsuario
        console.log('pessoa usuario')
        console.log(this.pessoaUsuario);

        this.setState({ carregando: true });
        this.mensagens.current.clear();

        if (!this.pessoaUsuario.emailPlataformaLirida && !this.pessoaUsuario.senha) {

            setTimeout(() => {
                this.setState({ carregando: false, });
                this.mensagens.current.show([
                    { severity: 'error', summary: 'Email nao informado', sticky: true },
                ]);
            }, 1000);
            return;

        }

        this.listaPesquisa = [
            this.util.criarObjetoPesquisa(
                "pessoa.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa.email_plataforma_lirida",
                this.pessoaUsuario.emailPlataformaLirida,
                "AND",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa.seq_pessoa_perfil",
                this.constante.seqPerfilOperador,
                "AND",
                "TEXTO"
            ),
        ]

        this.retornoListaPesquisa = await this.PessoaService.listarComFiltro(this.listaPesquisa)
        console.log("retorno da pesquisa", this.retornoListaPesquisa.objeto[0])
        this.pessoa = this.retornoListaPesquisa.objeto[0]

        if (!this.pessoa) {

            this.mensagens.current.show([
                { severity: 'error', summary: 'Usuario inválido', sticky: true },
            ]);
            this.setState({ carregando: false })

            return
        }

        else if (this.pessoa.senha !== this.pessoaUsuario.senha) {
            this.mensagens.current.show([
                { severity: 'error', summary: 'Senha inválida', sticky: true },
            ]);
            this.setState({ carregando: false })

            return
        }

        else if (this.pessoa.emailPlataformaLirida === this.pessoaUsuario.emailPlataformaLirida && this.pessoa.senha === this.pessoaUsuario.senha) {
            this.util.salvarLocalStorage('PESSOA_USUARIO', this.pessoa)
            this.setState({ carregando: false, })
            this.setState({ liberarAcesso: { ...this.state.liberarAcesso, documento: true } })
        }


    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }



    render() {
        return <div>
            {/* NAVEGAÇÃO */}
            {this.state.liberarAcesso.documento && <Navigate to="/documento" />}
            {this.state.liberarAcesso.recuperarSenha && <Navigate to="/recuperarSenha" />}
            {this.state.liberarAcesso.cadastro && <Navigate to="/cadastro" />}

            {/* TELA */}
            <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'var(--primary-color)', padding: 20 }}>
                <img src={LogoCross} alt="logo" style={{ height: 60 }} />
            </header>

            <main style={{ width: '90%', margin: '20px auto', display: 'flex', alignItems: 'center', gap: 80 }}>
                <img src={LogoAzul} alt="Crosslog" style={{ width: 400, objectFit: 'contain' }} />


                <form onSubmit={e => { e.preventDefault(); this.validarAcesso(e.target) }} style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <Messages ref={this.mensagens} />

                    <h1>Login</h1>

                    <div>
                        <label>Email</label><br />
                        <InputText
                            style={{ width: '100%' }}
                            onChange={pTexto => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario, emailPlataformaLirida: pTexto.target.value
                                }
                            })} />

                    </div>
                    <div>
                        <label>Senha</label><br />
                        <InputText
                            style={{ width: '100%' }}
                            onChange={pTexto => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario, senha: pTexto.target.value
                                }
                            })} />


                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button label="Recuperar-senha" type="button" className="p-button-link" style={{ padding: 0 }} onClick={() => this.navegar("recuperarSenha")} />
                        <Button label="Cadastre-se" type="button" className="p-button-link" style={{ padding: 0 }} onClick={() => this.navegar("cadastro")} />
                        <Button label="Entrar" type="submit" loading={this.state.carregando} />
                    </div>
                </form>
            </main>
        </div>
    }
}