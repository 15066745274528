import { Button } from "primereact/button";
import { Component } from "react";
import Header from "../components/Header";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";


import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import DepartamentoService from "lirida-back-service/Servicos/Departamento/DepartamentoService";
import FinanceiroService from 'lirida-back-service/Servicos/Financeiro/FinanceiroService';
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";

import Constante from "../util/Constante";
import Util from "../util/util";


export default class FinanceiroPg extends Component {

    constante = new Constante();
    util = new Util(this.constante.token, this.constante.url_base);
    departamentoService = new DepartamentoService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);


    state = {
        // LISTAS
        listaMovimentoFinanceiro: [],
        listaContaFinanceira: [],
        listaFormaPagamento: [],
        listaContaBancaria: [],

        detalheVisivel: false,
        liberarAcessoTelaLogin: false,
        liberarAcessoTelaDocumento: false,
        pessoaUsuario: new Pessoa(),

        /* Filtros */
        seqContaBancaria: null,
        seqFormaPagamento: null,
        seqContaFinanceira: '',
        status: '',
        dataCadastro_inicial: '',
        dataCadastro_final: '',
        dataProgramada_inicial: '',
        dataProgramada_final: '',
        dataBaixa_inicial: '',
        dataBaixa_final: '',
        dataCredito_inicial: '',
        dataCredito_final: '',
    }


    componentDidMount() {
        this.iniciar();

    }


    async iniciar() {
        this.retornoLocalStorage = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        this.pessoaUsuario = this.retornoLocalStorage;
        if (!this?.pessoaUsuario?._seqPessoa) {
            window.history.back()
            return
        }

        this.retornoListaContaFinanceira = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaContaFinanceira);
        this.retornoListaFormaPagamento = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaFormaPagamento);
        this.retornoListaContaBancaria = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaContaBancaria);

        this.listaContaFinanceira = this.retornoListaContaFinanceira.objeto;
        console.log("CONTA FINANCEIRA", this.listaContaFinanceira);
        this.listaFormaPagamento = this.retornoListaFormaPagamento.objeto;
        console.log("CONTA FORMA PAGAMENTO", this.listaFormaPagamento);

        this.listaContaBancaria = this.retornoListaContaBancaria.objeto;
        console.log("CONTA BANCARIA")
        console.log(this.listaContaBancaria);

        this.listarFinanceiro();

        this.setState({
            listaContaFinanceira: this.listaContaFinanceira,
            listaFormaPagamento: this.listaFormaPagamento,
            listaContaBancaria: this.listaContaBancaria,
            pessoaUsuario: this.pessoaUsuario,
        });

    }


    async listarFinanceiro() {
        this.lista = [
            this.util.criarObjetoPesquisa(
                "movimento_financeiro.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            )
        ]

        if (this?.state?.seqContaBancaria?._seq) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.seq_conta_bancaria",
                    this.state.seqContaBancaria._seq,
                    "AND",
                    "TEXTO"
                )
            )
        }

        if (this?.state?.seqFormaPagamento?._seq) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.seq_forma_pagamento",
                    this.state.seqFormaPagamento._seq,
                    "AND",
                    "TEXTO"
                )
            )
        }


        if (this?.state?.seqContaFinanceira?._seq) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.seq_conta_financeira",
                    this.state.seqContaFinanceira,
                    "AND",
                    "TEXTO"
                )
            )
        }

        if (this.state.status) {
            if (this.state.status === "0") {
                this.lista.push(
                    this.util.criarObjetoPesquisa(
                        "movimento_financeiro.qtde_baixa",
                        this.state.status,
                        "AND",
                        "NUMERO"
                    )
                );
            } else {
                this.lista.push(
                    this.util.criarObjetoPesquisa(
                        "AND movimento_financeiro.qtde_baixa > 0",
                        this.state.status,
                        "AND",
                        "MANUAL"
                    )
                );
            }
        }

        if (this.state.dataCadastro_inicial) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.data_cadastro",
                    this.state.dataCadastro_inicial,
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }


        if (this.state.dataCadastro_final) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.data_cadastro",
                    this.state.dataCadastro_final,
                    "AND",
                    "DATA_FINAL"
                )
            );
        }


        if (this.state.dataProgramada_inicial) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.data_programado",
                    this.util.formatarDataInput(this.state.dataProgramada_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }

        if (this.state.dataProgramada_final) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.data_programado",
                    this.util.formatarDataInput(this.state.dataProgramada_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }


        if (this.state.dataBaixa_inicial) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro_baixa.data_baixa",
                    this.util.formatarDataInput(this.state.dataBaixa_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }


        if (this.state.dataBaixa_final) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro_baixa.data_baixa",
                    this.util.formatarDataInput(this.state.dataBaixa_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }


        if (this.state.dataCredito_inicial) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro_baixa.data_credito",
                    this.util.formatarDataInput(this.state.dataCredito_inicial),
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }


        if (this.state.dataCredito_final) {
            this.lista.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro_baixa.data_credito",
                    this.util.formatarDataInput(this.state.dataCredito_final),
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        console.log(this.lista);
        this.retorno = await this.financeiroService.listarComFiltro(this.lista);
        console.log("================>")
        console.log(this.retorno.objeto)

        for (let i = 0; i < this.retorno.objeto.length; i++) {
            if (!this.retorno.objeto[i].valorTaxa) {
                this.retorno.objeto[i].valorTaxa = 0;
            }
            if (!this.retorno.objeto[i].valorLiquido) {
                this.retorno.objeto[i].valorLiquido = 0;
            }
            if (this.retorno.objeto[i].qtdeBaixa === 0) {
                this.retorno.objeto[i].qtdeBaixa = 'Não pago';
            }
            if (this.retorno.objeto[i].qtdeBaixa === 1) {
                this.retorno.objeto[i].qtdeBaixa = 'Pago';
            }
            this.retorno.objeto[i].valorProgramado = this.util.formatarValor(this.retorno.objeto[i].valorProgramado);
            this.retorno.objeto[i].valorTaxa = this.util.formatarValor(this.retorno.objeto[i].valorTaxa);
            this.retorno.objeto[i].valorLiquido = this.util.formatarValor(this.retorno.objeto[i].valorLiquido);
            this.retorno.objeto[i].dataProgramado = this.util.formatarData(this.retorno.objeto[i].dataProgramado);
            this.retorno.objeto[i]._dataCredito = this.util.formatarData(this.retorno.objeto[i]._dataCredito);
        }
        console.log('============>')
        console.log(this.retorno.objeto)
        this.setState({
            listaMovimentoFinanceiro: this.retorno.objeto,
            seqContaBancaria: null,
            seqFormaPagamento: null,
            seqContaFinanceira: null,
            // status: '',
            // dataCadastro_inicial: '',
            // dataCadastro_final: '',
            // dataProgramada_inicial: '',
            // dataProgramada_final: '',
            // dataBaixa_inicial: '',
            // dataBaixa_final: '',
            // dataCredito_inicial: '',
            // dataCredito_final: '',
        });
    }

    selecionarMovimentoFinanceiro(pFinanceiro) {
        window.open(this.constante.url_base_crosspag + pFinanceiro._seqMovimentoFinanceiro, '_blank')
    }

    selecionarContaBancaria(pContaBancaria) {
        this.contaBancaria = pContaBancaria;
        console.log(this.contaBancaria);
        this.setState({ seqContaBancaria: this.contaBancaria._seq })
    }



    render() {
        return <div>
            <Header />

            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <div>
                            <h1 style={{ fontSize: 22, }}>Financeiro</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Financeiro</h2>
                        </div>
                    </div>
                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>
                        <div>
                            <label>Conta bancaria</label><br />
                            <Dropdown style={{ width: '100%', }}
                                onChange={item => this.setState({ seqContaBancaria: item.value })}
                                value={this.state.seqContaBancaria}
                                options={this.state.listaContaBancaria}
                                optionLabel="_nome"
                                placeholder='Selecione' />

                        </div>
                        <div>
                            <label>Forma de pagamento</label><br />
                            <Dropdown style={{ width: '100%', }}
                                onChange={item => this.setState({ seqFormaPagamento: item.target.value })}
                                value={this.state.seqFormaPagamento}
                                options={this.state.listaFormaPagamento}
                                optionLabel="_nome"
                                placeholder='Selecione' />
                        </div>
                        <div>
                            <label>Conta financeira</label><br />
                            <Dropdown style={{ width: '100%', }}
                                onChange={item => this.setState({ seqContaFinanceira: item.target.value })}
                                value={this.state.seqContaFinanceira}
                                options={this.state.listaContaFinanceira}
                                optionLabel="_nome"
                                placeholder='Selecione' />
                        </div>
                        <div>
                            <label>Status</label><br />
                            <Dropdown style={{ width: '100%', }}
                                placeholder='Selecione' />
                        </div>
                        <div>
                            <label>Data cadastro inicial</label><br />
                            <Calendar style={{ width: '100%', }}
                                onChange={pTexto => this.setState({ dataCadastro_inicial: pTexto.target.value })}
                                value={this.state.dataCadastro_inicial}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data cadastro final</label><br />
                            <Calendar style={{ width: '100%', }}
                                onChange={pTexto => this.setState({ dataCadastro_final: pTexto.target.value })}
                                value={this.state.dataCadastro_final}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Label</label><br />
                            <Calendar style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Label</label><br />
                            <Calendar style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                        <Button label="Buscar" className="p-button-raised" onClick={() => this.listarFinanceiro()} />
                    </div>
                </div>

                <DataTable style={{ marginTop: 20 }} selectionMode="single" showGridlines removableSort value={this.state.listaMovimentoFinanceiro} onSelectionChange={e => this.selecionarMovimentoFinanceiro(e.value)}>
                <Column field="_seqMovimentoFinanceiro" header="ID" sortable></Column>
                    <Column field="_pesNome" header="Nome" sortable></Column>
                    <Column field="_fpNome" header="Forma pagamento" sortable></Column>
                    <Column field="_cfOperacao" header="Operação" sortable></Column>
                    <Column field="valorProgramado" header="Valor" sortable></Column>
                    <Column field="valorTaxa" header="Valor taxa" sortable></Column>
                    <Column field="valorLiquido" header="Valor líquido" sortable></Column>
                    <Column field="dataProgramado" header="Data programada" sortable></Column>
                    <Column field="_dataCredito" header="Data crédito" sortable></Column>
                    <Column field="qtdeBaixa" header="Status" sortable></Column>
                </DataTable>
            </main>
        </div>
    }
}