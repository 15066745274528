import { Component, createRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Navigate } from "react-router-dom";
import { Messages } from "primereact/messages";

import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";

import LogoCross from "../assets/imagens/headerLogo.png"
import LogoCadastro from "../assets/imagens/cadastro.png"

import Endereco from 'lirida-back-service/Servicos/Endereco/Endereco'

import Constante from "../util/Constante";
import Util from "../util/util";



export default class CadastroPg extends Component {

    mensagens = createRef();
    util = new Util();
    constante = new Constante()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base)
    endereco = new Endereco()


    state = {
        pessoaUsuario: new Pessoa(),

        liberarAcesso: {
            login: false,
        },
        carregando: false,
    }

    async salvar() {
        this.pessoaUsuario = this.state.pessoaUsuario
        this.pessoaUsuario.seqPessoaPerfil = this.constante.seqPerfilOperacional
        this.pessoaUsuario.seqUsuario = this.constante.seqUsuario
        console.log('pessoa usuario', this.pessoaUsuario);

        this.setState({ carregando: true });


        if (!this.pessoaUsuario.nome) {

            setTimeout(() => {
                this.setState({ carregando: false, });
                this.mensagens.current.show([
                    { severity: 'error', summary: ' Nome não informado', sticky: true },
                ]);
            }, 100);
            return
        }

        else if (!this.pessoaUsuario.emailPlataformaLirida) {

            setTimeout(() => {
                this.setState({ carregando: false, });
                this.mensagens.current.show([
                    { severity: 'error', summary: ' Email não informado', sticky: true },
                ]);
            }, 1000);
            return
        }

        else if (!this.pessoaUsuario.documento) {

            setTimeout(() => {
                this.setState({ carregando: false, });
                this.mensagens.current.show([
                    { severity: 'error', summary: ' CNPJ não informado', sticky: true },
                ]);
            }, 100);
            return
        }

        this.listaPesquisa = [
            this.util.criarObjetoPesquisa(
                "pessoa.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa.email_plataforma_lirida",
                this.pessoaUsuario.emailPlataformaLirida,
                "AND",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa.seq_pessoa_perfil",
                this.constante.seqPerfilOperacional,
                "AND",
                "TEXTO"
            ),
        ]

        this.retornoListaPesquisa = await this.pessoaService.listarComFiltro(this.listaPesquisa)
        console.log("retorno da pesquisa", this.retornoListaPesquisa.objeto[0])
        this.pessoa = this.retornoListaPesquisa.objeto[0]
        console.log("pessoa", this.pessoa);



        if (this.pessoa) {
            setTimeout(() => {
                this.setState({ carregando: false, });
                this.mensagens.current.show([
                    { severity: 'error', summary: 'Email já existe', sticky: true },
                ]);
            }, 100);
            return
        }

        this.retornoPessoaUsuario = await this.pessoaService.salvar(this.pessoaUsuario)
        this.pessoaUsuario = this.retornoPessoaUsuario.objeto
        console.log('PESSOA USUARIOO')
        console.log(this.retornoPessoaUsuario.objeto)
        this.setState({ carregando: false })


        if (this.retornoPessoaUsuario.resultado === false) {

            setTimeout(() => {
                this.setState({ carregando: false, });
                this.mensagens.current.show([
                    { severity: 'error', summary: 'Documento Invalido', sticky: true },
                ]);
            }, 100);
            return
        }

        await this.util.enviarEmail(this.constante.seqEmail, this.pessoaUsuario.emailPlataformaLirida, this.pessoaUsuario.nome, this.pessoaUsuario.senha)

        this.mensagens.current.show([
            { severity: 'success', summary: 'Seu cadastro foi realizado com sucesso !! enviamos a senha para o seu email.', sticky: true },])

    }

    navegar(pTela) {
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }

    render() {
        return <div>
            {/* NAVEGAÇÃO */}
            {this.state.liberarAcesso.login && <Navigate to="login" />}

            {/* TELA */}
            <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'var(--primary-color)', padding: 20 }}>
                <img src={LogoCross} alt="logo" style={{ height: 60 }} />
            </header>

            <main style={{ width: '90%', margin: '50px auto', display: 'flex', alignItems: 'center', gap: 150 }}>
                <img src={LogoCadastro} alt="Crosslog" style={{ width: 350, objectFit: 'contain' }} />

                <form onSubmit={e => { e.preventDefault(); this.salvar(e.target) }} style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <Messages ref={this.mensagens} />

                    <h1>Cadastre-se</h1>

                    <div>
                        <label>Nome</label><br />
                        <InputText
                            style={{ width: '100%' }}
                            onChange={pTexto => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario, nome: pTexto.target.value
                                }
                            })} />
                    </div>

                    <div>
                        <label>Email</label><br />
                        <InputText
                            style={{ width: '100%' }}
                            onChange={pTexto => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario, emailPlataformaLirida: pTexto.target.value
                                }
                            })} />
                    </div>

                    <div>
                        <label>Telefone</label><br />
                        <InputText
                            style={{ width: '100%' }}
                            onChange={pTexto => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario, telefonePlataformaLirida: this.util.formatarTelefone(pTexto.target.value)
                                }
                            })}
                            value={this.state.pessoaUsuario.telefonePlataformaLirida} />

                    </div>


                    <div>
                        <label>CNPJ-MEI</label><br />
                        <InputText
                            style={{ width: '100%' }}
                            onChange={pTexto => this.setState({
                                pessoaUsuario: {
                                    ...this.state.pessoaUsuario, documento: this.util.formatarCPFCNPJ(pTexto.target.value)
                                }
                            })}
                            value={this.state.pessoaUsuario.documento} />

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button label="Login" type="button" className="p-button-link" style={{ padding: 0 }} onClick={() => this.navegar("login")} />
                        <Button label="Cadastrar" type="submit" loading={this.state.carregando} />
                    </div>
                </form>
            </main>
        </div>
    }
}