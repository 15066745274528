export default class Constante {
  token = "123";
  url_base = 'http://192.168.1.20:8080/lirida/api/';
  // url_base = 'https://xoxota.lirida.com.br/crosslog/api/'
  // url_base = 'https://pallua.com.br/lirida_novo/api/';

  //urlBaseCrossPag
  url_base_crosspag = "https://crosspag.com.br/cobranca?id=";


  //seqUsuario
  seqUsuario = "3b77dc97-bdf8-411b-9c23-f2e77b777e04";

  // perfil
  seqPerfilRemetente = "2a21c456-16b2-4051-9d43-d9c7daa9df50";
  seqPerfilSolicitante = "2c2a38c0-3146-41fb-b41e-625286c153db";
  seqPerfilDestinatario = "832b762c-3442-415a-9841-f3e6c2cb6143";
  seqPerfilEntregador = "0156871e-6f0f-4753-b897-53670c2a9d56";
  seqPerfilOperacional = "7d4b78e4-6abc-44dd-8f68-1948a364f333";

  //tipocontato
  seqTpiContatoTelefone = "215e44df-7fb0-46ba-81ea-fc47984aa7ac";
  seqTpiContatoEmail = "787b95b2-d72b-46b4-8f94-9ec19f58b117";
  seqTpiContatowhatsApp = "40c9d9fa-df1f-49f1-8f90-59dc2001f093";
  seqTpiContatoInstagram = "84c45973-ac60-45f3-ac10-e5c0b8822537";
  seqTpiContatoFacebook = "3a9b7211-371f-4534-9c2f-a3cd44c9e866";
  seqTpiContatoSite = "d09ef7d1-675b-4047-adeb-ebda83102e7a";

  // tipoEndereco
  seqTipoEnderecoCobranca = "b4062983-6166-4c91-8319-a25287b505b2";
  seqTipoEnderecoEntrega = "7ffaf5b9-ad2e-4cf8-b30e-25a61fdcaf09";

  //TipoRelacionamento
  seqTipoRelacionamentoColeta = "44e43a96-c12a-4f02-9671-3f645637690e";
  seqTipoRelacionamentoEntrega = "61ca0d4f-7105-4ed1-84d0-8743f6a71bca";
  seqTipoRelacionamentoSolicitante = "8fded62f-c5fe-4ea8-9263-185db2eb9f25"
  seqTipoRelacionamentoConvitePendente = "de3b740a-0e99-4864-8d50-e96a0a006b97"
  seqTipoRelacionamentoConviteAceito = "41769689-e30c-451e-8552-3c5a6e8d444d"
  seqTipoRelacionamentoEntregaAceita = "0b6fb901-43c4-4b9e-b01c-0899d93d6716";

  //SeqTipodocumento
  seqTipoDocumentoPedidoVenda = "2c5e872c-3f69-4443-a111-30ef87d6d478";
  seqTipoDocumentoArquivoMorto = "1eb59e27-53c7-4f6a-b18d-1d4dd9af7dce";
  seqtipodocumentoConvite = "b8d62bdf-15bc-4789-ac99-b7ef003e6fc3";


  //seqTipoDocumentoProcesso
  seqTipoDocumentoProcessoSemAgendamento = "c429f4f1-7ae8-4a31-bd46-4219726a98f8";
  seqTipoDocumentoProcessoaAgendado = "64378b8b-8538-42ea-9aa0-5d6f763e4e6b";
  seqTipoDocumentoProcessofinalizado = "38908819-d521-4a44-a826-549caa6cdf03";

  seqTipoDocumentoProcessoSemAgendamentoMorto = "44c90e4b-2c2f-4afe-9601-3ab9ff0a7ad0";
  seqTipoDocumentoProcessoAgendadoMorto = "eebdce8a-fa2e-4fd1-906f-672cecba993c";
  seqTipoDocumentoProcessoFinalizadaMorto = "c6ac7a29-00de-41db-a711-a1e1fefc5603";

  seqTipoDocumentoProcessoConvidado = "632cac85-e3d7-43c9-8429-60301f953482";


  //seqItem
 
  seqItenVenda = "d80e4091-aa06-407f-bedf-81e7396b12cc";

  //Itemcategoria
  seqItemCategoria = "bfa22638-de87-48c8-bcae-f15364325754";

  // seqTabelaPreco
  seqTabelaPreco = "d19a0c02-d3af-4ff1-89f7-32bdc1643b51";

  //seqEmail
  seqEmail = "06f67e95-edfe-4e56-a5f5-58e1b60d154a"

  //seqContaBancaria
  seqContaBancaria = "51237697-38ad-4226-8ff5-8ea152a4e2ca"

  //seqContaFinanceira
  seqContaFinanceiraReceber = "e6664152-470f-4aed-8744-13d48b74732b"
  seqContaFinanceiraPagar = "58f5f80b-a11c-4a88-9e17-d75f308d22d5"

  //seqFormadePagamento
  seqFormaDePagamentoPix = "3447e95e-91f7-4e03-837a-40e2dbe31552"

  // seqTipoArquivo
  seqTipoArquivoInformacoesAdicionais = "8e4c8411-642e-413c-88ad-ff0112789012"

  //seqTipoAtendimento
  seqTipoAtendimentoInformacoesAdicionais = "1d8bd777-e1a3-43e4-9181-c4775591e725"

  //seqTipoEndereco

  SeqTipoEnderecoCobranca = "b4062983-6166-4c91-8319-a25287b505b2"
  SeqTipoEnderecoEntrega = "7ffaf5b9-ad2e-4cf8-b30e-25a61fdcaf09"

  //ID 

  idListaContaBancaria = 'CB'
  idListaContaFinanceira = 'CF'
  idListaFormaPagamento = 'FP'

}
