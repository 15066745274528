import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Messages } from "primereact/messages";

import Header from "../components/Header";

import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";

import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaRelacionamento from "lirida-back-service/Servicos/PessoaRelacionamento/PessoaRelacionamento"

import Constante from "../util/Constante";
import Util from "../util/util";

export default class FrotaPg extends Component {

    mensagens = createRef();
    constante = new Constante();
    util = new Util(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);


    state = {

        pessoaUsuario: new Pessoa(),
        transportador: new Pessoa(),
        relacionamentoTransportador: new PessoaRelacionamento(),
        detalheTransportador: new Pessoa(),

        // TELA
        telaRecrutar: false,
        telaConviteDetalhe: false,
        //LISTAS
        listaEmail: [],
        listaFrota: [],


        conteudo: '',

    }




    componentDidMount() {
        this.iniciar()
        this.listar()
    }


    async iniciar() {

        this.pessoaUsuario = this.state.pessoaUsuario
        this.retornoLocalStorage = await this.util.buscarLocalStorage('PESSOA_USUARIO')
        console.log("USUARIO LOCALSTORAGE", this.retornoLocalStorage)
        this.pessoaUsuario = this.retornoLocalStorage



    }

    async listar() {
        this.pessoaUsuario = this.state.pessoaUsuario

        this.listaPesquisa = [
            this.util.criarObjetoPesquisa(
                "pessoa_relacionamento.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa_relacionamento.seq_pessoa_relacionada",
                this.pessoaUsuario._seqPessoa,
                "AND",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa_relacionamento.seq_tipo_relacionamento",
                this.constante.seqTipoRelacionamentoConviteAceito,
                "AND",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "pessoa_relacionada.nome",
                this.state.conteudo,
                "AND",
                "TEXTO"
            ),


        ];

        this.retorno = await this.pessoaService.listarComFiltro(this.listaPesquisa)
        console.log(this.retorno.objeto)
        this.listaFrota = this.retorno.objeto

        this.setState({ listaFrota: this.listaFrota })
    }


    async selecionar(e) {
        console.log(e);
        this.setState({ telaConviteDetalhe: true })
        this.pessoa = e

        this.retorno = await this.pessoaService.buscarPorSeqPessoa(this.pessoa._seqPessoaRelacionada, this.constante.seqUsuario)
        console.log("busquei por seq", this.retorno.objeto);
        this.detalheTransportador = this.retorno.objeto

        this.setState({ detalheTransportador: this.detalheTransportador })
    }



    async salvar() {
        this.transportador = this.state.transportador
        this.listaEmail = this.state.listaEmail
        this.emailConvite = this.listaEmail.split(";")
        console.log(this.EmailConvite);


        for (let i = 0; i < this.emailConvite.length; i++) {


            //buscando por email
            this.transportador = new Pessoa();
            this.transportador.emailPlataformaLirida = this.emailConvite[i]
            this.retornoPessoaTransportador = await this.pessoaService.buscarPorEmail(this.transportador.emailPlataformaLirida, this.constante.seqUsuario);
            console.log(this.retornoBuscarEmail);
            this.pessoaTransportador = this.retornoPessoaTransportador.objeto;


            if (!this.pessoaTransportador) {
                setTimeout(() => {
                    this.mensagens.current.show([
                        { severity: 'error', summary: ' Email ou formatação está invalida, favor verifique novamente. ', sticky: true },
                    ]);
                }, 1000);
            }

            //salvando relacionamento
            this.relacionamentoTransportador = this.state.relacionamentoTransportador
            this.relacionamentoTransportador.seqUsuario = this.constante.seqUsuario
            this.relacionamentoTransportador.seqPessoa = this.pessoaUsuario._seqPessoa
            this.relacionamentoTransportador.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoConvitePendente
            this.relacionamentoTransportador.seqPessoaRelacionada = this.pessoaTransportador._seqPessoa
            this.relacionamentoTransportador.situacao = "ativo"

            this.retornoRealcionamento = await this.pessoaService.salvarRelacionamento(this.relacionamentoTransportador)
            console.log("relacionamento", this.retornoRealcionamento.objeto);
            console.log('----------------')

        }
    }



    render() {
        return <div>

            <Header />
            {this.state.telaConviteDetalhe === false ? <>
                {this.state.telaRecrutar === false ? <main style={{ width: '95%', margin: '20px auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                            <div>
                                <h1 style={{ fontSize: 22 }}>Minha frota</h1>
                                <h2 style={{ fontSize: 20, color: 'gray' }}>Minha frota</h2>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                            <InputText placeholder="Pesquisar" value={this.state.conteudo} onChange={e => this.setState({ conteudo: e.target.value })} />
                            <Button label="Buscar" className="p-button-raised" onClick={() => this.listar()} />
                            <Button label="Novo" className="p-button-raised" onClick={() => this.setState({ telaRecrutar: true })} />
                        </div>
                    </div>


                    <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                        <h2 style={{ fontSize: 22.5 }}>Minha frota</h2>
                        <DataTable selectionMode="single" showGridlines value={this.state.listaFrota} onSelectionChange={e => this.selecionar(e.value)}>

                            <Column field="_pessoaRelacionadaNome" header="Nome"></Column>
                            {/* <Column field="emailPlataformaLirida" header="Email"></Column> */}
                            {/* <Column field="telefonePlataformaLirida" header="telefone"></Column> */}
                            {/* <Column field="dataCadastro" header="Data de cadastro"></Column> */}
                            <Column field="situacao" header="Situação"></Column>
                        </DataTable>
                    </div>

                </main>


                    ///TELA CONVITE///

                    : <main style={{ width: '95%', margin: '20px auto' }}>
                        <Messages ref={this.mensagens} />

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                                <Button label={<i className="pi pi-arrow-left"></i>} className="p-button-raised" onClick={() => this.setState({ telaRecrutar: false })} />
                                <div>
                                    <h1 style={{ fontSize: 22 }}>Convite</h1>
                                    <h2 style={{ fontSize: 20, color: 'gray' }}>Convide os recrutadores para sua frota!</h2>

                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, marginLeft: "auto" }}>
                                {this.state.salvarCheck ?
                                    <Button label="Salvo" icon={<i className="pi pi-check"></i>} className="p-button-success" />
                                    :
                                    <Button label="Enviar" className="p-button-raised" onClick={() => this.salvar()} />
                                }
                            </div>

                        </div>


                        <div style={{ display: "flex", alignItems: "center", gap: 5, marginTop: 10 }}>
                            <Button icon="pi pi-send" className="p-button-rounded p-button-info p-button-outlined" aria-label="User" />
                            <h3>Convite</h3>
                        </div>

                        <div style={{ display: "grid", gridTemplateColumns: " 1fr ", gap: 20, border: '1px solid lightgray', padding: 20, borderRadius: 3, marginTop: 10, alignItems: "center" }}>

                            <div>
                                <label>Email</label><br />
                                <InputTextarea style={{ width: '100%', height: 200 }}
                                    placeholder="Informe aqui uma lista de email, separados por ponto e vírgula. EXEMPLO:  email_1@email.com;email_2@email.com "
                                    onChange={(pTexto) => this.setState({ listaEmail: pTexto.target.value })} />
                            </div>
                        </div>
                    </main>}
            </> :


                ///TELA DETALHE CONVITE///

                <main style={{ width: '95%', margin: '20px auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                            <Button label={<i className="pi pi-arrow-left"></i>} className="p-button-raised" onClick={() => this.setState({ telaConviteDetalhe: false })} />
                            <div>
                                <h1 style={{ fontSize: 22 }}>Detalhe transportador</h1>
                                <h2 style={{ fontSize: 20, color: 'gray' }}>Detalhe transportador</h2>

                            </div>
                        </div>

                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: " 1fr 1fr", gap: 20, border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                        <div>
                            <label>Nome</label><br />
                            <InputText value={this.state.detalheTransportador.nome} disabled style={{ width: '100%' }} />
                        </div>
                        <div>
                            <label>Email</label><br />
                            <InputText value={this.state.detalheTransportador.emailPlataformaLirida} disabled style={{ width: '100%' }} />
                        </div>
                        <div>
                            <label>Telefone</label><br />
                            <InputText value={this.state.detalheTransportador.telefonePlataformaLirida} disabled style={{ width: '100%' }} />
                        </div>
                        <div>
                            <label>Data de cadastro</label><br />
                            <InputText value={this.state.detalheTransportador.dataCadastro} disabled style={{ width: '100%' }} />
                        </div>

                        <div>
                            <label>Situação</label><br />
                            <InputText value={this.state.detalheTransportador.situacao} disabled style={{ width: '100%' }} />
                        </div>
                        
                    </div>
                </main>}

        </div>
    }
}



