import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import Util_api from 'lirida-back-service/Servicos/Util/Util';
import Constante from './Constante';
export default class Util {
  constante = new Constante();
  util_api = new Util_api(this.constante.token, this.constante.url_base);

  async buscarUrlBaseArquivo() {
    let retorno = await this.util_api.buscarUrlBaseArquivo();
    retorno = await retorno.json();
    return retorno;
  }

  async buscarUrlBaseFinanceiro() {
    let retorno = await this.util_api.buscarUrlBaseFinanceiro();
    retorno = await retorno.json();
    return retorno;
  }

  async buscarLocalStorage(pCampo) {
    let retorno = localStorage.getItem(pCampo);
    retorno = await JSON.parse(retorno);
    console.log(retorno);
    return retorno;
  }

  salvarLocalStorage(pCampo, pValor) {
    let valor = JSON.stringify(pValor);
    localStorage.setItem(pCampo, valor);
    console.log(pCampo + ' salvo localstorage com sucesso')
  }

  async removerLocalStorage(pCampo) {
    let retorno = localStorage.removeItem(pCampo);
    return retorno;
  }

  formatarData(pData) {
    if (!pData) {
      return 'não informado'
    }

    this.data = String(pData);
    this.data = this.data.split('T')[0];
    this.data = this.data.replace('-', '/').replace('-', '/');
    return this.data;
  }


  formatarTelefone(value) {
    let r = value.replace(/\D/g, '');
    r = r.replace(/^0/, '');

    if (r.length > 11) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 7) {
      r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (value.trim() !== '') {
      r = r.replace(/^(\d*)/, '($1');
    }

    return r;
  }


  formatarCPFCNPJ(pTexto) {
    let t = pTexto;

    if (t.length > 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    }

    if (t.length <= 15) {
      return t.replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    }
  }

  async buscarCep(pCep) {
    let retorno = await fetch('https://viacep.com.br/ws/' + pCep + '/json')
    retorno = await retorno.json();

    return retorno;
  }

  async validarDocumento(pTipoDocumento, pDocumento) {
    this.retorno = await this.util_api.validarDocumento(pTipoDocumento, pDocumento);
    this.retorno = await this.retorno.json();
    return this.retorno;
  }


  priceFormat(value) {
    let price = String(Number(value).toFixed(2)).split('').reverse().join('').replace('.', '')
    price = price.replace(/(\d{2})/, '$1,')
    price = price.replace(/(\d{3}(?!$))/g, '$1.')
    return 'R$ ' + price.split('').reverse().join('')
  }

  currencyBRL(value) {
    const formattedValue = value.toLocaleString(
      'pt-BR',
      { style: 'currency', currency: 'BRL' }
    );

    return formattedValue;
  };



  retornaPesquisa(pCampo, pConteudo, pOperacao, pTipo) {
    let pesquisa = new Pesquisa();

    pesquisa.campo = pCampo;
    pesquisa.conteudo = pConteudo;
    pesquisa.operacao = pOperacao;
    pesquisa.tipo = pTipo;

    return pesquisa;
  }


  buscarParametrosUrl() {
    if (!window.location.search) {
      return null;
    }

    let query = window.location.search.slice(1);
    let partes = query.split("&");
    let data = {};
    partes.forEach(function (parte) {
      let chaveValor = parte.split("=");
      let chave = chaveValor[0];
      let valor = chaveValor[1];
      data[chave] = valor;
    });

    return data;
  }

  criarObjetoPesquisa(pCampo, pConteudo, pOperacao, pTipo) {
    let pesquisa = new Pesquisa()

    pesquisa.campo = pCampo;
    pesquisa.conteudo = pConteudo;
    pesquisa.operacao = pOperacao;
    pesquisa.tipo = pTipo;

    return pesquisa;

  }


  async enviarEmail(pSeqEmail, pEmail, pNome, pSenha) {
    await this.util_api.enviarEmail(
      {
        seqEmail: pSeqEmail,
        email: pEmail,
        parametro: {
          nome: pNome,
          email: pEmail,
          senha: pSenha
        }
      }
    )
  }

  formatarValor(value) {
    if (value) {
      this.valorFormatado = Number(value).toLocaleString(
        'pt-BR',
        { style: 'currency', currency: 'BRL' }
      );
    } else {
      this.valorFormatado = Number(0).toLocaleString(
        'pt-BR',
        { style: 'currency', currency: 'BRL' }
      );
    }
    return this.valorFormatado;
  };

  async listarLista(pSeqUsuario, pID) {
    this.retorno = await this.util_api.listarLista(pSeqUsuario, pID);
    this.retorno = this.retorno.json();
    return this.retorno;
  }

  formatarDataInput(pData) {
    if (String(pData).includes('/')) {
      this.data = String(pData).split('/').reverse().join('-');
    } else if (String(pData).includes('-')) {
      this.data = String(pData).split('-').reverse().join('/');
    } else {
      this.data = pData;
    }

    return this.data;
  }
  async pegarBase64(pArquivo) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(pArquivo);
      reader.onload = () => {
        let base64 = reader.result;
        base64 = base64.split(",")[1];
        resolve(base64);
      };
      reader.onerror = error => reject(error);
    })
  }

  async listarBanco(pConteudo) {
    this.listaBanco = await this.util_api.listarBanco(pConteudo);
    this.listaBanco = await this.listaBanco.json();
    return this.listaBanco;
  }

  formatarDataBanco(pData) {
    let data = pData.toLocaleDateString();
    data = `${data.split('/').join('-')}T00:00:00`;
    console.log("DATA PARA O BANCO")
    console.log(data)
    return data
  }


  formatarDataCalendar(pData) {
    let data = String(pData)
    data = `${data.split('T')[0].split('-').reverse().join('-')}T03:00:00.000Z`;
    return new Date(data)
  }
  formatarCEP(pTexto) {
    this.texto = pTexto;
    return this.texto.replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
  }

}

