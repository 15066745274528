import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"; 
import 'primeicons/primeicons.css';

//Telas
import DocumentoPg from './pages/documento';
import FinanceiroPg from './pages/financeiro';
import PerfilPg from './pages/perfil';
import LoginPg from './pages/login';
import CadastroPg from './pages/cadastro';
import RecuperarSenhaPg from './pages/recuperarSenha';
import DetalheDocumentopg from './pages/detalheDocumento';
import FrotaPg from './pages/frota';
import HotSite from './hotsite';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes >
    <Route path="*" element={<Navigate to="/hotsite" />} />
    <Route path="/hotsite" element={<HotSite />} />
    <Route path="/login" element={<LoginPg />} />
    <Route path="/cadastro" element={<CadastroPg />} />
    <Route path="/recuperarSenha" element={<RecuperarSenhaPg />} />
    <Route path="/documento" element={<DocumentoPg />} />
    <Route path="/detalhe" element={<DetalheDocumentopg />} />
    <Route path="/financeiro" element={<FinanceiroPg />} />
    <Route path="/perfil" element={<PerfilPg />} /> 
    <Route path="/frota" element={<FrotaPg />} />
    
   
  </Routes>
</BrowserRouter>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
